<template>
  <div>
    <div class="experiment-box">
      <div class="back" @click="backTo">
        <i class="el-icon-s-fold"></i>
      </div>
      <div class="step-box">
        <template v-for="(item,index) in content">
          <div class="step-list" v-if="!item.ignore" :key="item.id" @click="changeStep(index)">
            <div :class="`step-circular ${(step == index ? 'active' : (step < index?'done':''))}`"></div>
            <div class="step-list-before"></div>
            <div class="step-list-after"></div>
            <div class="step-circular-title">{{ item.name }}</div>
          </div>
        </template>
      </div>
      <div class="content-box">
        <div class="content-active">
          <div class="content-active-title">
            当前实验进度 - {{stepContent.name}}
          </div>
          <div class="content-active-item">
            <div class="title" v-if="step != 0">请准备以下实验材料并完成信息录入..</div>
            <div class="title"  v-if="step == 0"><br></div>
            <div class="content-active-step" v-if="step != 0">
              <div @click="changeItems(index)" :class="`item ${no === index ? 'current' : (item.complete?'active':'')}`" :key="index" v-for="(item, index) in stepContent.forms">
                {{ item.label }}
                <div v-if="item.complete" class="cur">
                  <i class="el-icon-check"></i>
                </div>
                <div style="font-size: 12px;color: #999"  v-if="item.usageType != 4">({{item.usageDesc}})</div>
              </div>
            </div>
            <div v-else>
              <div class="souce-radio">
                <!--                <el-radio-group @change="changeStep1" v-model="isFreeze">-->
                <!--                  <el-radio :label="0" :value="0" border>冻存前</el-radio>-->
                <!--                  <el-radio :label="1" :value="1" border disabled>冻存后复苏</el-radio>-->
                <!--                </el-radio-group>-->
              </div>
              <img style="width: 80%; margin: 0 auto;display: block" src="~@/assets/terminal/t-bg.png"/>
            </div>
            <div class="content-active-nextRemindMsg" v-if="step != 0 && remindInfo.currentNeedTime">
              <span>时间倒计时</span>
              <good-timer ref="statistic" :value="remindInfo.currentNeedTime" />
              <span>此步需要进行的内容</span>
              <div class="content-active-remindMsg">{{ remindInfo.currentRemindMsg }}</div>
            </div>
            <!--            <div class="submit-button" @click="getcode"><i class="el-icon-full-screen"></i>扫描条形码</div>-->
          </div>
          <div class="content-active-input">
            <div class="content-active-input-info">
              <div class="content-active-input-title" v-if="step != 0">{{ activeForm.label }}</div>
              <div class="content-active-input-title" v-if="step == 0">{{ stepContent.name }}</div>
              <!--              <div class="content-active-input-text" v-for="item in option[content[step].forms[no].value].column" :key="item.label">-->
              <!--                {{item.label}}: {{content[step].forms[no].form[item.prop]}}-->
              <!--              </div>-->

              <template v-if="step == 0">
                <div v-if="isFreeze == 0">
                  <!--                <div class="content-active-input-text" >-->
                  <!--                  样本来源: {{sourceData.$type}}-->
                  <!--                </div>-->
                  <!--                <div class="content-active-input-text" >-->
                  <!--                  癌种: {{sourceData.cancerType}}-->
                  <!--                </div>-->
                  <div class="content-active-input-text" >
                    医院: {{sourceData.hospitalName}}
                  </div>
                  <div class="content-active-input-text" >
                    医生: {{sourceData.doctorName}}
                  </div>
                  <div class="content-active-input-text" >
                    科室: {{sourceData.officeName}}
                  </div>
                  <div class="content-active-input-text" >
                    患者: {{sourceData.patientName}}
                  </div>
                  <div class="content-active-input-text" >
                    样本编号: {{sourceData.sampleCode}}
                  </div>
                  <div class="content-active-input-text" >
                    类器官代次: {{sourceData.generation}}
                  </div>
                  <div class="content-active-input-text" >
                    传代对象: {{sourceData.expObjectCode}}
                  </div>
                  <el-dialog
                      title="样本信息"
                      :visible.sync="YBDialogVisible"
                      center>

                    <el-form :model="sourceData" :rules="rules" label-width="100px" :inline="true" ref="form" >
                      <el-form-item label="样本编号" prop="sampleCode">
                        <el-input v-model="sourceData.sampleCode" disabled></el-input>
                      </el-form-item>
                      <el-form-item label="来源类型" prop="source">
                        <div>
                          <el-select placeholder="请选择" v-model="sourceData.source" disabled>
                            <el-option
                                :value="item.value"
                                :key="item.value"
                                :label="item.label"
                                v-for="item in sourceOptions">
                            </el-option>
                          </el-select>
                        </div>
                      </el-form-item>
                      <el-form-item label="医院">
                        <el-input v-model="sourceData.hospitalName" placeholder="请输入医院" disabled></el-input>
                      </el-form-item>
                      <el-form-item label="科室">
                        <el-input v-model="sourceData.officeName" placeholder="请输入科室" disabled></el-input>
                      </el-form-item>
                      <el-form-item label="医生">
                        <el-input v-model="sourceData.doctorName" placeholder="请输入医生" disabled></el-input>
                      </el-form-item>
                      <el-form-item label="样本患者">
                        <el-input v-model="sourceData.patientName" placeholder="请输入患者" disabled></el-input>
                      </el-form-item>
                      <el-form-item label="类器官代次" prop="generation">
                        <el-select @change="getExpObjectCodeList($event)" placeholder="请选择" v-model="sourceData.generation">
                          <el-option
                              :key="item.value"
                              :label="item.label"
                              :value="item.value"
                              v-for="item in generationOptions">
                          </el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item label="传代对象" prop="expObjectCode">
                        <el-select @change="setFormExpObjectCode($event)" placeholder="请选择" v-model="sourceData.expObjectCode">
                          <el-option
                              :value="item.value"
                              :key="item.value"
                              :label="item.label"
                              v-for="item in expObjectCodeOptions">
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-form>
                    <span slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="searchYbInfo">确 定</el-button>
                  </span>
                  </el-dialog>
                  <el-button style="margin-top: 20px" icon="el-icon-search" v-if="sourceData?.tubeList.length" @click="dialogVisible1 = true">选择孔板</el-button>
                  <el-dialog
                      title="选择孔板"
                      :visible.sync="dialogVisible1"
                      width="600px">
                    <template v-for="(item, index) in sourceData.tubeList">
                      <test-tube :key="index" :showtip="false" :value="item.form.tube" :hole="item.form.$specifications" freeze />
                    </template>
                  </el-dialog>
                </div>
                <div v-if="isFreeze == 1">
                  <div class="title" >请录入冻存管编号…</div>
                  <div class="content-active-input-text" >
                    样本编号: {{sourceData.$type}}
                  </div>
                  <div class="content-active-input-text" >
                    冻存编号: {{sourceData.cancerType}}
                  </div>
                  <div class="content-active-input-text" >
                    冻存代次: {{sourceData.hospitalName}}
                  </div>
                  <div class="content-active-input-text" >
                    冻存对象: {{sourceData.doctorName}}
                  </div>
                </div>
              </template>
              <template v-if="activeForm.form && step !== 0">
                <div class="content-active-input-text" >
                  条码: {{activeForm.form.barcode}}
                </div>
                <div class="content-active-input-text" >
                  名称: {{activeForm.form.nameStr || activeForm.form.$name}}
                </div>
                <div class="content-active-input-text" >
                  型号: {{activeForm.form.modelStr || activeForm.form.$model}}
                </div>
                <div class="content-active-input-text" >
                  规格: {{activeForm.form.specificationsStr|| activeForm.form.$specifications}}
                </div>
                <div class="content-active-input-text" >
                  品牌: {{activeForm.form.brand}}
                </div>
                <div class="content-active-input-text" >
                  生产日期: {{activeForm.form.productionDate}}
                </div>
                <div class="content-active-input-text" >
                  使用日期: {{activeForm.form.usePeriod}}
                </div>
                <el-button style="margin-top: 20px" icon="el-icon-search" type="primary" v-if="activeForm.form.objectType == '9'" @click="dialogVisible = true">查看</el-button>
                <el-dialog
                    title="细胞培养板"
                    :visible.sync="dialogVisible"
                    width="600px">
                  <test-tube :showtip="false" v-model="activeForm.form.tube" :hole="activeForm.form.specificationsStr" disabled></test-tube>
                </el-dialog>
              </template>

            </div>
            <div style="text-align: center">
              <el-button @click="clearFrom" v-if="activeForm.form !== {}" type="primary">清除</el-button>
            </div>
            <div v-if="!codeStatus" @click="getCodeSubmit" class="submit-button">确认</div>
            <div v-if="activeForm.skip" @click="goSkip" class="submit-button">跳过</div>
            <div v-if="codeStatus" class="submit-button ok"><i class="el-icon-check"></i>已确认</div>
          </div>
        </div>
        <div class="content-next">
          <div class="content-next-title" v-if="content.length != step + 1">下一步 - {{nextContent.name}}</div>
          <div class="content-next-title" v-else>
            无
          </div>
          <div class="content-next-wrap">
            <div class="title" v-if="content.length != step + 1">请提前准备好以下材料..</div>
            <div class="content-next-ul" v-if="content.length != step + 1">
              <div class="content-next-li" v-for="(item, index) in nextContent.forms" :key="index">{{index + 1}}.{{item.label}}</div>
            </div>
            <div class="content-active-nextRemindMsg">
              <span>下一步所需时间</span><br>
              {{ remindInfo.nextNeedTime.split(':')[0] }} 小时
              {{ remindInfo.nextNeedTime.split(':')[1] }} 分钟
              {{ remindInfo.nextNeedTime.split(':')[2] }} 秒
              <div>
                <span>下一步了需要进行的内容</span><br>
                {{ remindInfo.nextContent }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="experiment-button-wrap">
        <div class="wait" v-if="!nextStatus"><i class="el-icon-loading"></i>请等待完成</div>
        <div class="next" v-if="nextStatus" @click="nextStep">开始下一步<i class="el-icon-right"></i></div>
      </div>
    </div>
  </div>
</template>
<script>
import { expObjectCodeDic, generationDic, getDetail as getExperiment, getScan, tubeList, getRemind } from '@/api/exp/experiment'
import option from '@/components/awd-test-step/index'
import { getFullDetailBySampleCode } from '@/api/market/sample'
import { submit } from '@/api/exp/drugtest'
import { mapGetters } from 'vuex'
import { getCurrentByTypeGen, submitCulture } from '@/api/setting/experimenttemplate'
import { baseUrl } from '@/config'
import testTube from '@/components/form/test-tube'
import goodTimer from '@/components/view-comp/good-timer'
export default {
  components: {
    testTube, goodTimer
  },
  computed: {
    ...mapGetters(['userInfo'])
  },
  data () {
    return {
      no: 0,
      type: 0,
      experiment: {},
      templateType: null,
      content: [],
      stepNavi: [],
      code: '',
      option,
      step: 0,
      inputString: '',
      inputCache: '',
      codeStatus: false,
      codeReady: false, // 扫码状态
      sourceData: {
        tubeList: []
      },
      sourceOptions: [{
        label: '患者',
        value: '1'
      }, {
        label: '动物',
        value: '2'
      }],
      generationOptions: [],
      expObjectCodeOptions: [],
      rules: {
        source: [
          { required: true, message: '请选择来源类型', trigger: 'blur' }
        ],
        sampleCode: [
          { required: true, message: '请输入样本编号', trigger: 'blur' }
        ],
        freezePipeCodes: [
          { required: true, message: '请输入冻存管编号', trigger: 'blur' }
        ],
        experimentCode: [
          { required: true, message: '请输入冻存编号', trigger: 'blur' }
        ],
        generation: [
          { required: true, message: '请输入代次', trigger: 'blur' }
        ],
        expObjectCode: [
          { required: true, message: '请选择传代对象', trigger: 'blur' }
        ],
        operatorTime: [
          { required: true, message: '请填写操作时间', trigger: 'blur' }
        ]
      },
      isFreeze: 0,
      webSocket: null,
      url: baseUrl.split('://')[1] + '/websocket',
      types: '2',
      // 下一步的小步骤
      nextContent: {},
      // 当前大步骤
      stepContent: {
        forms: []
      },
      // 当前小步骤数据
      activeForm: {
        form: {},
        skip: false
      },
      nextStatus: false, // 是否允许开始下一步
      realStep: 0,
      dialogVisible: false,
      dialogVisible1: false,
      YBDialogVisible: false,
      remindInfo: {
        nextNeedTime: '0:0:0'
      }
    }
  },
  created () {
    this.templateType = this.$route.query.templateType
    this.experiment.id = this.$route.query.experimentId
    this.detail()
    // 页面打开就建立连接，根据业务需要
    this.initSocket()
  },
  mounted () {
    this.getEleCode()
  },

  destroyed () {
    // 页面销毁关闭连接
    this.webSocket.close()
  },
  methods: {
    getTubeData () {
      for (let i = 0; i < this.content.length; i++) {
        if (this.content[i].id === 12) {
          for (let j = 0; j < this.content[i].forms.length; j++) {
            if (this.content[i].forms[j].value === '9') {
              return this.content[i].forms[j].form.tube
            }
          }
        }
      }
    },
    getRemind (type) {
      type = type || this.experiment.type
      getRemind({ expType: type, id: this.experiment.id, stepNo: this.step + 1 }).then(res => {
        if (res.data.currentStartTime === '0') {
          this.remindInfo = {
            currentNeedTime: res.data.currentNeedTime,
            currentRemindMsg: res.data.currentRemindMsg,
            nextContent: res.data.nextContent,
            nextNeedTime: res.data.nextNeedTime || '0:0:0'
          }
          this.$refs.statistic.stop()
        } else {
          const now = new Date()
          const target = new Date(res.data.currentStartTime)
          const date = target.getTime() + (res.data.currentNeedTime * 1000) - now.getTime()
          this.remindInfo = {
            currentNeedTime: date / 1000,
            currentRemindMsg: res.data.currentRemindMsg,
            nextContent: res.data.nextContent,
            nextNeedTime: res.data.nextNeedTime || '0:0:0'
          }
          this.$refs.statistic.start()
        }
      })
    },
    searchYbInfo () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.YBDialogVisible = false
        }
      })
    },
    getExpObjectCodeList (e) {
      const data = {
        source: this.sourceData.source,
        hospitalId: this.sourceData.hospitalId,
        officeId: this.sourceData.officeId,
        doctorId: this.sourceData.doctorId,
        patientId: this.sourceData.patientId,
        sampleId: this.sourceData.id,
        sampleCode: this.sourceData.sampleCode,
        generation: this.sourceData.generation,
        hospitalName: this.sourceData.hospitalName,
        officeName: this.sourceData.officeName,
        doctorName: this.sourceData.doctorName,
        patientName: this.sourceData.patientName
      }
      this.expObjectCodeOptions = []
      expObjectCodeDic(data).then(res => {
        this.expObjectCodeOptions = res.data
      })
    },
    setFormExpObjectCode (e) {
      // this.form.expObjectCode = e
      this.sourceData.expObjectCode = e
      this.handleFilter()
    },
    handleFilter () {
      this.getTubeList()
    },
    getTubeList () {
      this.sourceData.tubeList = []
      if (typeof (this.sourceData.expObjectCode) !== 'undefined') {
        const data = {
          source: this.sourceData.source,
          hospitalId: this.sourceData.hospitalId,
          officeId: this.sourceData.officeId,
          doctorId: this.sourceData.doctorId,
          patientId: this.sourceData.patientId,
          sampleId: this.sourceData.id,
          sampleCode: this.sourceData.sampleCode,
          generation: this.sourceData.generation,
          hospitalName: this.sourceData.hospitalName,
          officeName: this.sourceData.officeName,
          doctorName: this.sourceData.doctorName,
          expObjectCode: this.sourceData.expObjectCode,
          patientName: this.sourceData.patientName
        }
        tubeList(data).then(res => {
          this.sourceData.tubeList = res.data
        })
      }
    },
    changeStep1 (index) {
      this.isFreeze = index
    },
    changeItems (index) {
      this.no = index
      this.activeForm = this.stepContent.forms[index]
      if (this.stepContent.id !== 12 && this.activeForm.value === '9') {
        this.activeForm.form.tube = this.getTubeData()
      }
    },
    changeStep (index) {
      if (index === 0) {
        this.step = 0
        this.sourceData = this.experiment.content[0].form
        this.stepContent = this.experiment.content[0]
      } else if (index <= this.experiment.stepNo) {
        this.step = index
        this.stepContent = this.experiment.content[index]
        this.activeForm = this.experiment.content[index].forms[0]
        if (this.stepContent.id !== 12 && this.activeForm.value === '9') {
          this.activeForm.form.tube = this.getTubeData()
        }
        this.getNextContent(index)
        this.getNo()
        this.getRemind()
      }
    },
    clearFrom () {
      this.activeForm.form = {}
      if (this.step === 0) {
        this.sourceData = {}
      }
    },
    // 建立连接
    initSocket () {
      // 有参数的情况下：
      const url = `wss://${this.url}/${this.userInfo.id}`
      // 没有参数的情况:接口
      // let url1 = 'ws://localhost:9998'
      this.webSocket = new WebSocket(url)
      this.webSocket.onopen = this.webSocketOnOpen
      this.webSocket.onclose = this.webSocketOnClose
      this.webSocket.onmessage = this.webSocketOnMessage
      this.webSocket.onerror = this.webSocketOnError
    },
    // 建立连接成功后的状态
    webSocketOnOpen () {
      console.log('websocket连接成功')
    },
    // 获取到后台消息的事件，操作数据的代码在onmessage中书写
    webSocketOnMessage (res) {
      // res就是后台实时传过来的数据
      const data = JSON.parse(res.data)
      console.log(res, data.data.uid, this.experiment.id)
      if (data.data.uid === this.experiment.id) {
        this.detail()
        this.no++
      }
      this.$notify({
        title: '提示',
        message: data.message,
        duration: 0
      })
      // 给后台发送数据
      this.webSocket.send('发送数据')
    },
    // 关闭连接
    webSocketOnClose () {
      this.webSocket.close()
      console.log('websocket连接已关闭')
    },
    // 连接失败的事件
    webSocketOnError (res) {
      console.log('websocket连接失败')
      // 打印失败的数据
      console.log(res)
    },
    getEleCode () {
      document.onkeypress = (e) => {
        // 如果触发了回车事件(扫码结束时间)
        console.log(e)
        if (e.key === 'Enter') {
          this.inputString = this.inputCache
          this.getcode(this.inputString)
          this.inputCache = ''
        } else {
          this.inputCache += e.key
        }
      }
    },
    goSkip () {
      this.codeReady = true
      this.getCodeSubmit()
    },
    getCodeSubmit () {
      if (this.codeReady) {
        if (this.no < this.content[this.step].forms.length) {
          this.content[this.step].forms[this.no].form = {
            ...this.content[this.step].forms[this.no].form,
            ...this.activeForm.form,
            sampleCode: this.experiment.sampleCode
          }
          submitCulture({
            expId: this.experiment.id,
            stepNo: this.step + 1,
            stepName: this.content[this.step].name,
            formId: this.content[this.step].forms[this.no].id,
            form: this.content[this.step].forms[this.no].form
          }).then(res => {
            if (res.success) {
              this.$message({
                message: '提交成功',
                type: 'success'
              })
            }
            this.codeReady = false
            this.experiment.content[this.step].forms[this.no].complete = true
            this.experiment.content[this.step].forms[this.no].scanTime = res.data.content[this.step].forms[this.no].scanTime
            this.no++
            this.codeStatus = false
            if (this.no === 1) {
              this.$refs.statistic.start()
            }
            if (this.stepContent.forms.length <= this.no) {
              this.nextStatus = true
            } else {
              this.stepContent = res.data.content[this.step]
              this.activeForm = this.stepContent.forms[this.no]
              if (this.stepContent.id !== 12 && this.activeForm.value === '9') {
                this.activeForm.form.tube = this.getTubeData()
              }
            }
          })
        }
      } else {
        if (this.activeForm.usageType !== '4') {
          this.codeStatcus = true
          this.no++
          if (this.stepContent.forms.length <= this.no) {
            this.nextStatus = true
          } else {
            this.activeForm = this.stepContent.forms[this.no]
            if (this.stepContent.id !== 12 && this.activeForm.value === '9') {
              this.activeForm.form.tube = this.getTubeData()
            }
          }
        } else {
          this.$message({
            message: '请先扫码',
            type: 'error'
          })
        }
        // if (this.content[this.step].forms[this.no]) {
        //   if (this.content[this.step].forms[this.no].form.tube) {
        //     this.no++
        //   }
        //   if (this.content[this.step].forms[this.no].usageType !== '4') {
        //     this.no++
        //   }
        // }
        //
        // if (this.content[this.step].forms.length <= this.no) {
        //   this.codeStatus = true
        // }
      }
    },
    detail () {
      if (this.step === 0 && !this.experiment.id) {
        getCurrentByTypeGen(this.templateType).then(res => {
          if (res.success) {
            if (res.data.content != null) {
              this.content = res.data.content
              this.stepNavi = res.data.stepNavi
              this.experiment.templateId = res.data.id
              this.experiment.content = this.content
              this.checkedStep = this.content.map((item, index) => {
                return index
              })
              this.stepContent = res.data.content[0]
              this.activeForm = {
                form: {},
                skip: false,
                usageType: '0'
              }
              this.content = this.content.map(item => {
                for (let i = 0; i < item.forms.length; i++) {
                  item.forms[i].form = item.forms[i].form || {}
                }
                return item
              })
              this.getRemind(res.data.type)
              this.getNextContent(this.step)
            } else {
              this.$message({
                type: 'error',
                message: '暂无可用实验模板!'
              })
            }
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            })
          }
        })
      } else {
        getExperiment(this.experiment.id).then(res => {
          this.stepNo = res.data.stepNo - 1
          this.step = res.data.stepNo - 1
          this.experiment = res.data
          this.content = this.experiment.content
          this.stepContent = res.data.content[this.stepNo]
          this.getNo()
          this.getRemind()
          this.getNextContent(this.stepNo)
        })
      }
    },
    nextStep () {
      if (this.step === 0) {
        this.experiment.stepNo = this.realStep
        this.experiment.content[0].form = this.sourceData
        submit(this.experiment).then(res => {
          if (res.success) {
            this.experiment = res.data
            this.content = this.experiment.content
            this.stepNo = this.step
            this.step = this.realStep + 1
            this.codeStatus = false
            this.nextStatus = false
            this.no = 0
            this.stepContent = res.data.content[this.stepNavi[1].id - 1]
            this.activeForm = this.stepContent.forms[0]
            this.getNextContent(this.step)
            this.getRemind()
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            })
          }
        })
      } else {
        this.experiment.stepNo = this.realStep + 2
        submit(this.experiment).then(res => {
          if (res.success) {
            this.experiment = res.data
            this.content = this.experiment.content
            this.stepNo = res.data.stepNo
            this.step = res.data.stepNo - 1
            this.codeStatus = false
            this.nextStatus = false
            this.no = 0
            this.stepContent = res.data.content[res.data.stepNo - 1]
            this.getNextContent(this.step)
            this.getNo()
            this.getRemind()
            this.$refs.statistic.stop()
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            })
          }
        })
      }
    },
    getNo () {
      const items = this.content[this.step].forms
      this.no = 0
      for (let i = 0; i < items.length; i++) {
        if (items[i].complete) {
          this.no++
        }
      }
      const idx = this.no >= this.stepContent.forms.length ? this.stepContent.forms.length - 1 : this.no
      this.activeForm = this.stepContent.forms[idx]
      if (this.no >= items.length) {
        this.codeStatus = true
        this.nextStatus = true
      }
    },
    getcode (code) {
      if (this.step === 0) {
        getFullDetailBySampleCode(code).then(response => {
          this.sourceData = { ...this.sourceData, ...response.data }
          function timestampToTime () {
            const date = new Date()// 时间戳为10位需*1000，时间戳为13位的话不需乘1000
            const Y = date.getFullYear() + '-'
            const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
            const D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
            const h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
            const m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
            const s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
            return Y + M + D + h + m + s
          }
          this.sourceData.operatorName = this.userInfo.name
          this.sourceData.operatorId = this.userInfo.id
          this.sourceData.sampleId = this.sourceData.id
          this.sourceData.operatorTime = timestampToTime()
          this.sourceData.freezePipeCodes = ''
          this.YBDialogVisible = true
          const data = {
            sampleId: response.data.id,
            source: response.data.source,
            hospitalId: response.data.hospitalId,
            hospitalName: response.data.hospitalName,
            officeId: response.data.officeId,
            officeName: response.data.officeName,
            doctorId: response.data.doctorId,
            doctorName: response.data.doctorName,
            patientId: response.data.patientId,
            patientName: response.data.patientName
          }
          generationDic(data).then(res => {
            this.generationOptions = res.data
          })
        })
      } else {
        if (this.activeForm.skip) {
          const items = this.content[this.step].forms
          this.no++
          if (this.no >= items.length) {
            this.codeStatus = true
          }
          return
        }
        if (this.activeForm.usageType !== '4') {
          this.$message({
            type: 'error',
            message: `请在${this.activeForm.usageDesc}完成实验！`
          })
          return
        }
        // if (code === '1') {
        //   // 缓冲液
        //   code = 'KLC0010206012210000423032024032000005'
        // } else if (code === '2') {
        //   // 离心管
        //   code = 'EP00021101011234567823030123042112345'
        // } else if (code === '3') {
        //   // TIP
        //   code = 'TIP010101002211000023030124042512345'
        // } else if (code === '4') {
        //   // 消化液
        //   code = 'KLC0010101012210000322101523101400100'
        // } else if (code === '5') {
        //   // 终止液
        //   code = 'KLC00101020122100323031924031900101'
        // }
        getScan(code, this.activeForm.value).then(res => {
          if (!res.success) {
            this.$message({
              type: 'error',
              message: res.msg
            })
          }
          if (res.data.objectType !== this.activeForm.value) {
            this.$message({
              type: 'error',
              message: `当前非 ${this.activeForm.label} 的条形码，请检查条形码类型!`
            })
          } else {
            this.activeForm.form = res.data
            this.codeReady = true
            this.getCodeSubmit()
            this.$message({
              message: '扫码成功',
              type: 'success'
            })
          }
        })
      }
    },
    backTo () {
      this.$router.go(-1)
    },
    getNextContent (step) {
      this.realStep = step
      const _step = this.realStep
      if (this.content[_step + 1]) {
        this.nextContent = this.content[_step + 1]
        if (this.nextContent.ignore) {
          this.realStep++
          this.getNextContent(this.realStep)
        }
      } else {
        this.nextContent = {}
      }
    },

    chouseItem (index) {
      this.type = index
    }
  }
}
</script>

<style lang="scss">
.content-active-nextRemindMsg{
  background: #F2F6FC;
  border-radius: 5px;
  position: absolute;
  left: 30px;
  right: 30px;
  bottom: 30px;
  line-height: 30px;
  padding: 20px 40px;
  color: #303133;
  span{
    color: #76838f;
    font-size: 12px;
  }
  .el-statistic .con{
    justify-content: flex-start;
    span{
      line-height:50px;
    }
  }
}
.content-active-remindMsg{
  color: #303133;
}
.experiment-box{
  background: #f1f4fc;
  height: 100vh;
  width: 100vw;
  display: block;
  position: relative;
  .souce-radio{
    text-align: center;
  }
  .back{
    color: #fff;
    position: absolute;
    font-size: 30px;
    padding: 0 10px;
    line-height: 60px;
    border-right: 1px solid #fff;
    border-bottom: 1px solid #fff;
    opacity: .8;
    background: #79a0be;
    border-bottom-right-radius: 5px;
  }
  .step-box{
    background: #4085BA;
    padding: 30px;
    overflow: auto;
    width: 100%;
    display: flex;

    .step-list{
      color: #fff;
      position: relative;
      text-align: center;
      white-space: nowrap;
      .step-circular{
        border-radius: 50px;
        background: #B5DFFF;
        width: 30px;
        height: 30px;
        box-sizing: content-box;
        border: 5px solid #6cb1e7;
        margin: 0 auto;
        margin-bottom: 10px;
        z-index: 2;
        position: relative;
        cursor: pointer;
        &.done{
          background: #fff;
          border: 5px solid #B5DFFF;
          cursor: unset;
        }
        &.active{
          cursor: unset;
          background: #FFF600;
          border: 5px solid #99b97e;
        }
      }
      &:first-of-type{
        .step-list-before{
          display: none;
        }
      }
      &:last-of-type{
        .step-list-after{
          display: none;
        }
      }
      .step-circular-title{
        padding: 0 30px;
      }
      .step-list-before{
        z-index: 1;
        position: absolute;
        height: 1px;
        width: 50%;
        left: 0;
        right: 50%;
        top: 20px;
        background: #fff;
      }
      .step-list-after{
        z-index: 1;
        position: absolute;
        height: 1px;
        width: 50%;
        left: 50%;
        right: 0;
        top: 20px;
        background: #fff;
      }
    }
  }
  .content-box{
    display: flex;
    position: absolute;
    top: 150px;
    left: 30px;
    right: 30px;
    bottom: 80px;
    justify-content: space-between;
    overflow: hidden;
    .content-active{
      width: 60%;
      position: relative;
      .content-active-title{
        background: #fff;
        border-radius: 10px;
        text-align: center;
        color: #4085BA;
        height: 60px;
        width: 100%;
        line-height: 60px;
        font-size: 22px;
      }
      .content-active-item{
        border-radius: 10px;
        background: #fff;
        position: absolute;
        top: 65px;
        bottom: 0;
        left: 0;
        right: 40%;
        .content-active-step{
          padding: 30px;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          .item{
            width: calc(50% - 5px);
            background: #F1F1F1;
            color: #4875A3;
            text-align: center;
            margin-bottom: 10px;
            border-radius: 10px;
            padding: 30px 0;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            position: relative;
            .cur{
              color: #fff;
              position: absolute;
              right: -30px;
              top: -30px;
              height: 60px;
              width: 60px;
              background: #49a703;
              text-align: center;
              display: none;
              transform: rotate(45deg);
              i{
                margin-top: 40px;
                transform: rotate(-45deg);
              }
            }
            &.active{
              border: 2px solid #49a703;
              background: #f7ffe4;
              color: #49a703;
              .cur{
                display: block;
              }
            }
            &.current{
              color: #000;
              border: 1px solid #4F72A9;
              background: #FFE157;
            }
          }
        }
      }
      .content-active-input{
        border-radius: 10px;
        background: #fff;
        position: absolute;
        overflow: hidden;
        top: 65px;
        bottom: 0;
        left: calc(60% + 5px);
        right: 0;
        background-image: url("~@/assets/terminal/saoma.png");
        background-repeat: no-repeat;
        background-position: center;
        .content-active-input-text{
          font-size: 12px;
          margin-top: 10px;
          color: #4085BA;
        }
        .content-active-input-info{
          padding: 30px;
        }
        .content-active-input-title{
          border-left: 3px solid #4085BA;
          padding-left: 5px;
          font-size: 14px;
          line-height: 16px;
          margin-bottom: 20px;
        }
      }
    }
    .content-next{
      position: relative;
      background: #fff;
      border-radius: 10px;
      width: calc(40% - 5px);
      padding: 20px;
      .content-next-title{
        border-bottom: 1px solid #4085BA;
        color: #4085BA;
        line-height: 20px;
        padding-bottom: 20px;
        font-size: 22px;
        text-align: center;
      }
      .content-next-ul{
        padding:0 30px;
        .content-next-li{
          padding: 0 20px;
          line-height: 40px;
          margin-bottom: 5px;
          background: #E7EEF7;
          border-radius: 100px;
          color: #3476AD;
        }
      }

    }
    .submit-button{
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      text-align: center;
      border-top: 1px solid #8bb4d4;
      line-height: 60px;
      color: #3476AD;
      background: #fff;
      font-weight: bold;
      i{
        margin-right: 5px;
      }
      &.ok{
        color: #49a703;
        background: #f7ffe4;
      }
    }
    .title{
      line-height: 60px;
      text-align: center;
      color: #3476AD;
    }
  }
  .experiment-button-wrap{
    position: absolute;
    bottom: 0;
    height: 70px;
    left: 30px;
    right: 40px;
    display: flex;
    justify-content: flex-end;
    i{
      margin: 0 10px
    }
    font-weight: bold;
    .wait{
      background: #ffffff;
      color: #a2a2a2;
      line-height: 60px;
      height: 60px;
      padding: 0 30px;
      border-radius: 100px;
      margin-left: 20px;
    }
    .next{
      background: #4085BA;
      color: #fff;
      line-height: 60px;
      height: 60px;
      padding: 0 15px 0 30px;
      border-radius: 100px;
      margin-left: 20px;
    }
  }
}

</style>
