import { request } from '@/util/http'

export const getList = (current, size, params) => {
  return request({
    url: '/setting/experimenttemplate/list',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const getDetail = (id) => {
  return request({
    url: '/setting/experimenttemplate/detail',
    method: 'get',
    params: {
      id
    }
  })
}
export const getDetailVr = (id) => {
  return request({
    url: '/vr/setting/experimenttemplate/detail',
    method: 'get',
    params: {
      id
    }
  })
}
export const remove = (ids) => {
  return request({
    url: '/setting/experimenttemplate/remove',
    method: 'post',
    params: {
      ids
    }
  })
}

export const submit = (row) => {
  return request({
    url: '/setting/experimenttemplate/submit',
    method: 'post',
    data: row
  })
}
export const submitVr = (row) => {
  return request({
    url: '/vr/setting/experimenttemplate/submit',
    method: 'post',
    data: row
  })
}

export const copyTemplate = (row) => {
  return request({
    url: '/vr/setting/experimenttemplate/copy',
    method: 'post',
    data: row
  })
}
export const getCurrentByType = (type) => {
  return request({
    url: '/setting/experimenttemplate/current/' + type,
    method: 'get'
  })
}

export const getNaviByType = (type) => {
  return request({
    url: '/setting/experimenttemplate/navi/current/' + type,
    method: 'get'
  })
}

export const getCurrentByTypeGen = (type) => {
  return request({
    url: '/setting/experimenttemplate/navi/current/' + type,
    method: 'get'
  })
}

export const testB = (type) => {
  return request({
    url: '/exp/primaryCulture/test',
    method: 'get'
  })
}
export const submitCulture = (data) => {
  return request({
    url: '/exp/primaryCulture/navi/submit',
    method: 'post',
    data
  })
}
export const submitsubCulture = (data) => {
  return request({
    url: '/exp/subCulture/navi/submit',
    method: 'post',
    data
  })
}
