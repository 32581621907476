import { request } from '@/util/http'

export const getList = (current, size, params) => {
  return request({
    url: '/market/sample/page',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const getDetail = (id) => {
  return request({
    url: '/market/sample/detail',
    method: 'get',
    params: {
      id
    }
  })
}

export const remove = (ids) => {
  return request({
    url: '/market/sample/remove',
    method: 'post',
    params: {
      ids
    }
  })
}

export const submit = (row) => {
  return request({
    url: '/market/sample/submit',
    method: 'post',
    data: row
  })
}

export const moduleExcel = () => {
  return request({
    url: '/market/sample/download',
    method: 'get',
    responseType: 'blob'
  })
}

export const getFullDetail = (barcode) => {
  return request({
    url: '/market/sample/getFullDetail',
    method: 'get',
    params: {
      barcode
    }
  })
}

export const getSampleDetail = (barcode) => {
  return request({
    url: '/navigate/sample/' + barcode,
    method: 'get'
  })
}

export const getFullDetailBySampleCode = (sampleCode) => {
  return request({
    url: '/market/sample/getFullDetailBySampleCode',
    method: 'get',
    params: {
      sampleCode
    }
  })
}

export const getSampleDict = (params) => {
  return request({
    url: '/market/sample/dict',
    method: 'get',
    params: {
      ...params
    }
  })
}

export const getNumByStatisticType = (statisticType) => {
  return request({
    url: '/market/sample/getNumByStatisticType',
    method: 'get',
    params: {
      statisticType
    }
  })
}

export const getLifeCycle = (id) => {
  return request({
    url: '/market/sample/getLifeCycle',
    method: 'get',
    params: {
      id
    }
  })
}

export const getSampleNo = () => {
  return request({
    url: '/market/sample/getSampleNo',
    method: 'get'
  })
}
