import { request } from '@/util/http'

export const getList = (current, size, params) => {
  return request({
    url: '/setting/set/list',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const getDetail = (id) => {
  return request({
    url: '/setting/set/detail',
    method: 'get',
    params: {
      id
    }
  })
}

export const remove = (ids) => {
  return request({
    url: '/setting/set/remove',
    method: 'post',
    params: {
      ids
    }
  })
}

export const submit = (row) => {
  return request({
    url: '/setting/set/submit',
    method: 'post',
    data: row
  })
}

export const getListByCode = (code) => {
  return request({
    url: '/setting/set/code/' + code,
    method: 'get'
  })
}
// 获取终端字典
export const getUsageType = () => {
  return request({
    url: '/sys/dict/code/interaction_type',
    method: 'get'
  })
}

// 获取颜色字典
export const getMsgColor = () => {
  return request({
    url: '/setting/set/code/msg_color',
    method: 'get'
  })
}
