<template>
  <div>
    <primar v-if="templateType == 1"></primar>
    <subcultrue v-if="templateType == 2"></subcultrue>
    <druggest v-if="templateType == 5"></druggest>
  </div>
</template>
<script>
import primar from '@/views/terminal/pad/primary'
import subcultrue from '@/views/terminal/pad/subcultrue'
import druggest from '@/views/terminal/pad/druggest'
export default {
  components: {
    primar,
    subcultrue,
    druggest
  },
  data () {
    return {
      templateType: null
    }
  },
  mounted () {
    this.templateType = this.$route.query.templateType
  }
}
</script>
