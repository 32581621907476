import { request } from '@/util/http'

export const getList = (current, size, params) => {
  return request({
    url: '/exp/primaryCulture/page',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const getDetail = (id) => {
  return request({
    url: '/exp/consumables/detail',
    method: 'get',
    params: {
      id
    }
  })
}

export const remove = (ids) => {
  return request({
    url: '/exp/primaryCulture/remove',
    method: 'post',
    params: {
      ids
    }
  })
}

export const submit = (row) => {
  return request({
    url: '/exp/primaryCulture/submit',
    method: 'post',
    data: row
  })
}

export const nameList = () => {
  return request({
    url: '/exp/consumables/nameList',
    method: 'get'
  })
}

export const deletePrimary = (id) => {
  return request({
    url: '/exp/primaryCulture/delete/' + id,
    method: 'post'
  })
}

// 获取模板列表
export const frameList = (type) => {
  return request({
    url: '/vr/setting/experimenttemplate/dict',
    method: 'get',
    params: {
      type
    }
  })
}
// 根据选择的模板获取步骤列表
export const getStepByTemplate = (templateId) => {
  return request({
    url: '/vr/setting/experimenttemplate/getSteps',
    method: 'get',
    params: {
      templateId
    }
  })
}
// 获取步骤详情

export const getStepDetail = (stepId) => {
  return request({
    url: '/vr/exp/primaryCulture/step/detail',
    method: 'get',
    params: {
      stepId
    }
  })
}
// 保存原代培养
export const submitVr = (data) => {
  return request({
    url: '/vr/exp/primaryCulture/submit',
    method: 'post',
    data
  })
}
// 获取实验步骤列表
export const getTestStepList = (id) => {
  return request({
    url: '/vr/exp/primaryCulture/step/list',
    method: 'get',
    params: {
      id
    }
  })
}

