// import testTube from '@/components/form/test-tube'
// import testTubeDosing from '@/components/form/test-tube-dosing'
// import testTubeDay from '@/components/view-comp/test-tube-day'
import { Message } from 'element-ui'
import { fileSaveUrl } from '@/config/index'
const option = {
  1: {
    name: '消化液',
    submitBtn: false,
    emptyBtn: false,
    column: [
      {
        label: '条码',
        prop: 'barcode',
        row: true
      },
      {
        label: '名称',
        prop: 'name',
        type: 'select',
        props: {
          label: 'label',
          value: 'name'
        },
        cascader: ['model'],
        dicUrl: `exp/reagent/list?objectType=1`,
        rules: [
          {
            required: true,
            message: '请选择品牌',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '型号',
        prop: 'model',
        type: 'select',
        cascader: ['specifications'],
        props: {
          label: 'label',
          value: 'model'
        },
        dicUrl: `exp/reagent/list?objectType=1&name={{key}}`,
        rules: [
          {
            required: true,
            message: '请选择型号',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '规格',
        prop: 'specifications',
        type: 'select',
        cascader: ['brand'],
        props: {
          label: 'label',
          value: 'specifications'
        },
        dicUrl: `exp/reagent/list?objectType=1&name={{name}}&model={{model}}`,
        rules: [
          {
            required: true,
            message: '请选择规格',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '品牌',
        prop: 'brand',
        type: 'select',
        cascader: ['productionDate', 'code'],
        props: {
          label: 'brand',
          value: 'brand'
        },
        dicUrl: `exp/reagent/list?objectType=1&name={{name}}&model={{model}}&specifications={{specifications}}`,
        rules: [
          {
            required: true,
            message: '请选择品牌',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '生产日期',
        prop: 'productionDate',
        type: 'select',
        cascader: ['usePeriod', 'code'],
        props: {
          label: 'productionDate',
          value: 'productionDate'
        },
        dicUrl: `exp/reagent/list?objectType=1&name={{name}}&model={{model}}&specifications={{specifications}}&brand={{brand}}`,
        rules: [
          {
            required: false,
            message: '请选择生产日期',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '使用期限',
        prop: 'usePeriod',
        type: 'select',
        props: {
          label: 'usePeriod',
          value: 'usePeriod'
        },
        dicUrl: `exp/reagent/list?objectType=1&name={{name}}&model={{model}}&specifications={{specifications}}&brand={{brand}}&productionDate={{productionDate}}`
      },
      {
        label: '编号',
        prop: 'code',
        rules: [{
          required: true,
          message: '请输入编号'
        }]
      },
      {
        label: '用量',
        prop: 'amount',
        rules: [{
          required: true,
          message: '请输入消化液用量'
        }]
      }]
  },
  2: {
    name: '终止液',
    submitBtn: false,
    emptyBtn: false,
    column: [
      {
        label: '条码',
        prop: 'barcode',
        row: true
      },
      {
        label: '名称',
        prop: 'name',
        type: 'select',
        props: {
          label: 'label',
          value: 'name'
        },
        cascader: ['model'],
        dicUrl: `exp/reagent/list?objectType=2`,
        rules: [
          {
            required: true,
            message: '请选择品牌',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '型号',
        prop: 'model',
        type: 'select',
        cascader: ['specifications'],
        props: {
          label: 'label',
          value: 'model'
        },
        dicUrl: `exp/reagent/list?objectType=2&name={{key}}`,
        rules: [
          {
            required: true,
            message: '请选择型号',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '规格',
        prop: 'specifications',
        type: 'select',
        cascader: ['brand'],
        props: {
          label: 'label',
          value: 'specifications'
        },
        dicUrl: `exp/reagent/list?objectType=2&name={{name}}&model={{model}}`,
        rules: [
          {
            required: true,
            message: '请选择规格',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '品牌',
        prop: 'brand',
        type: 'select',
        cascader: ['productionDate', 'code'],
        props: {
          label: 'brand',
          value: 'brand'
        },
        dicUrl: `exp/reagent/list?objectType=2&name={{name}}&model={{model}}&specifications={{specifications}}`,
        rules: [
          {
            required: true,
            message: '请选择品牌',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '生产日期',
        prop: 'productionDate',
        type: 'select',
        cascader: ['usePeriod', 'code'],
        props: {
          label: 'productionDate',
          value: 'productionDate'
        },
        dicUrl: `exp/reagent/list?objectType=2&name={{name}}&model={{model}}&specifications={{specifications}}&brand={{brand}}`,
        rules: [
          {
            required: false,
            message: '请选择生产日期',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '使用期限',
        prop: 'usePeriod',
        type: 'select',
        props: {
          label: 'usePeriod',
          value: 'usePeriod'
        },
        dicUrl: `exp/reagent/list?objectType=2&name={{name}}&model={{model}}&specifications={{specifications}}&brand={{brand}}&productionDate={{productionDate}}`
      },
      {
        label: '编号',
        prop: 'code',
        rules: [{
          required: true,
          message: '请输入编号'
        }]
      },
      {
        label: '用量',
        prop: 'amount',
        rules: [{
          required: true,
          message: '请输入用量'
        }]
      },
      {
        label: '消化时长',
        prop: 'digestionTime'
      // type: 'datetime',
      // format: 'yyyy-MM-dd HH:mm:ss',
      // valueFormat: 'yyyy-MM-dd HH:mm:ss'
      }]
  },
  3: {
    name: '冻存液',
    submitBtn: false,
    emptyBtn: false,
    column: [
      {
        label: '条码',
        prop: 'barcode',
        row: true
      },
      {
        label: '名称',
        prop: 'name',
        type: 'select',
        props: {
          label: 'label',
          value: 'name'
        },
        cascader: ['model'],
        dicUrl: `exp/reagent/list?objectType=3`,
        rules: [
          {
            required: true,
            message: '请选择品牌',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '型号',
        prop: 'model',
        type: 'select',
        cascader: ['specifications'],
        props: {
          label: 'label',
          value: 'model'
        },
        dicUrl: `exp/reagent/list?objectType=3&name={{key}}`,
        rules: [
          {
            required: true,
            message: '请选择型号',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '规格',
        prop: 'specifications',
        type: 'select',
        cascader: ['brand'],
        props: {
          label: 'label',
          value: 'specifications'
        },
        dicUrl: `exp/reagent/list?objectType=3&name={{name}}&model={{model}}`,
        rules: [
          {
            required: true,
            message: '请选择规格',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '品牌',
        prop: 'brand',
        type: 'select',
        cascader: ['productionDate', 'code'],
        props: {
          label: 'brand',
          value: 'brand'
        },
        dicUrl: `exp/reagent/list?objectType=3&name={{name}}&model={{model}}&specifications={{specifications}}`,
        rules: [
          {
            required: true,
            message: '请选择品牌',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '生产日期',
        prop: 'productionDate',
        type: 'select',
        cascader: ['usePeriod', 'code'],
        props: {
          label: 'productionDate',
          value: 'productionDate'
        },
        dicUrl: `exp/reagent/list?objectType=3&name={{name}}&model={{model}}&specifications={{specifications}}&brand={{brand}}`,
        rules: [
          {
            required: false,
            message: '请选择生产日期',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '使用期限',
        prop: 'usePeriod',
        type: 'select',
        props: {
          label: 'usePeriod',
          value: 'usePeriod'
        },
        dicUrl: `exp/reagent/list?objectType=3&name={{name}}&model={{model}}&specifications={{specifications}}&brand={{brand}}&productionDate={{productionDate}}`
      },
      {
        label: '编号',
        prop: 'code',
        rules: [{
          required: true,
          message: '请输入编号'
        }]
      },
      {
        label: '用量',
        prop: 'amount',
        rules: [{
          required: true,
          message: '请输入冻存液用量'
        }]
      }]
  },
  4: {
    name: '缓冲液',
    submitBtn: false,
    emptyBtn: false,
    column: [
      {
        label: '条码',
        prop: 'barcode',
        row: true
      },
      {
        label: '名称',
        prop: 'name',
        type: 'select',
        props: {
          label: 'label',
          value: 'name'
        },
        cascader: ['model'],
        dicUrl: `exp/reagent/list?objectType=4`,
        rules: [
          {
            required: true,
            message: '请选择品牌',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '型号',
        prop: 'model',
        type: 'select',
        cascader: ['specifications'],
        props: {
          label: 'label',
          value: 'model'
        },
        dicUrl: `exp/reagent/list?objectType=4&name={{key}}`,
        rules: [
          {
            required: true,
            message: '请选择型号',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '规格',
        prop: 'specifications',
        type: 'select',
        cascader: ['brand'],
        props: {
          label: 'label',
          value: 'specifications'
        },
        dicUrl: `exp/reagent/list?objectType=4&name={{name}}&model={{model}}`,
        rules: [
          {
            required: true,
            message: '请选择规格',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '品牌',
        prop: 'brand',
        type: 'select',
        cascader: ['productionDate', 'code'],
        props: {
          label: 'brand',
          value: 'brand'
        },
        dicUrl: `exp/reagent/list?objectType=4&name={{name}}&model={{model}}&specifications={{specifications}}`,
        rules: [
          {
            required: true,
            message: '请选择品牌',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '生产日期',
        prop: 'productionDate',
        type: 'select',
        cascader: ['usePeriod', 'code'],
        props: {
          label: 'productionDate',
          value: 'productionDate'
        },
        dicUrl: `exp/reagent/list?objectType=4&name={{name}}&model={{model}}&specifications={{specifications}}&brand={{brand}}`,
        rules: [
          {
            required: false,
            message: '请选择生产日期',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '使用期限',
        prop: 'usePeriod',
        type: 'select',
        props: {
          label: 'usePeriod',
          value: 'usePeriod'
        },
        dicUrl: `exp/reagent/list?objectType=4&name={{name}}&model={{model}}&specifications={{specifications}}&brand={{brand}}&productionDate={{productionDate}}`
      },
      {
        label: '编号',
        prop: 'code',
        rules: [{
          required: true,
          message: '请输入编号'
        }]
      },
      {
        label: '用量',
        prop: 'amount',
        rules: [{
          required: true,
          message: '请输入缓冲液用量'
        }]
      }]
  },
  5: {
    name: '细胞活性检测试剂',
    submitBtn: false,
    emptyBtn: false,
    column: [
      {
        label: '条码',
        prop: 'barcode',
        row: true
      },
      {
        label: '名称',
        prop: 'name',
        type: 'select',
        props: {
          label: 'label',
          value: 'name'
        },
        cascader: ['model'],
        dicUrl: `exp/reagent/list?objectType=5`,
        rules: [
          {
            required: true,
            message: '请选择品牌',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '型号',
        prop: 'model',
        type: 'select',
        cascader: ['specifications'],
        props: {
          label: 'label',
          value: 'model'
        },
        dicUrl: `exp/reagent/list?objectType=5&name={{key}}`,
        rules: [
          {
            required: true,
            message: '请选择型号',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '规格',
        prop: 'specifications',
        type: 'select',
        cascader: ['brand'],
        props: {
          label: 'label',
          value: 'specifications'
        },
        dicUrl: `exp/reagent/list?objectType=5&name={{name}}&model={{model}}`,
        rules: [
          {
            required: true,
            message: '请选择规格',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '品牌',
        prop: 'brand',
        type: 'select',
        cascader: ['productionDate', 'code'],
        props: {
          label: 'brand',
          value: 'brand'
        },
        dicUrl: `exp/reagent/list?objectType=5&name={{name}}&model={{model}}&specifications={{specifications}}`,
        rules: [
          {
            required: true,
            message: '请选择品牌',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '生产日期',
        prop: 'productionDate',
        type: 'select',
        cascader: ['usePeriod', 'code'],
        props: {
          label: 'productionDate',
          value: 'productionDate'
        },
        dicUrl: `exp/reagent/list?objectType=5&name={{name}}&model={{model}}&specifications={{specifications}}&brand={{brand}}`,
        rules: [
          {
            required: false,
            message: '请选择生产日期',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '使用期限',
        prop: 'usePeriod',
        type: 'select',
        props: {
          label: 'usePeriod',
          value: 'usePeriod'
        },
        dicUrl: `exp/reagent/list?objectType=5&name={{name}}&model={{model}}&specifications={{specifications}}&brand={{brand}}&productionDate={{productionDate}}`
      },
      {
        label: '编号',
        prop: 'code',
        rules: [{
          required: true,
          message: '请输入编号'
        }]
      },
      {
        label: '用量',
        prop: 'amount',
        rules: [{
          required: true,
          message: '请输入用量'
        }]
      }]
  },
  6: {
    name: '化合物',
    submitBtn: false,
    emptyBtn: false,
    column: [
      {
        label: '条码',
        prop: 'barcode',
        row: true
      },
      {
        label: '名称',
        prop: 'name',
        type: 'select',
        props: {
          label: 'label',
          value: 'name'
        },
        cascader: ['model'],
        dicUrl: `exp/reagent/list?objectType=6`,
        rules: [
          {
            required: true,
            message: '请选择品牌',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '型号',
        prop: 'model',
        type: 'select',
        cascader: ['specifications'],
        props: {
          label: 'label',
          value: 'model'
        },
        dicUrl: `exp/reagent/list?objectType=6&name={{key}}`,
        rules: [
          {
            required: true,
            message: '请选择型号',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '规格',
        prop: 'specifications',
        type: 'select',
        cascader: ['brand'],
        props: {
          label: 'label',
          value: 'specifications'
        },
        dicUrl: `exp/reagent/list?objectType=6&name={{name}}&model={{model}}`,
        rules: [
          {
            required: true,
            message: '请选择规格',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '品牌',
        prop: 'brand',
        type: 'select',
        cascader: ['productionDate', 'code'],
        props: {
          label: 'brand',
          value: 'brand'
        },
        dicUrl: `exp/reagent/list?objectType=6&name={{name}}&model={{model}}&specifications={{specifications}}`,
        rules: [
          {
            required: true,
            message: '请选择品牌',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '生产日期',
        prop: 'productionDate',
        type: 'select',
        cascader: ['usePeriod', 'code'],
        props: {
          label: 'productionDate',
          value: 'productionDate'
        },
        dicUrl: `exp/reagent/list?objectType=6&name={{name}}&model={{model}}&specifications={{specifications}}&brand={{brand}}`,
        rules: [
          {
            required: false,
            message: '请选择生产日期',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '使用期限',
        prop: 'usePeriod',
        type: 'select',
        props: {
          label: 'usePeriod',
          value: 'usePeriod'
        },
        dicUrl: `exp/reagent/list?objectType=6&name={{name}}&model={{model}}&specifications={{specifications}}&brand={{brand}}&productionDate={{productionDate}}`
      },
      {
        label: '编号',
        prop: 'code',
        rules: [{
          required: true,
          message: '请输入编号'
        }
        ]
      },
      {
        label: '用量',
        prop: 'amount',
        rules: [{
          required: true,
          message: '请输入冻存液用量'
        }
        ]
      }]
  },
  7: {
    name: '培养基',
    submitBtn: false,
    emptyBtn: false,
    column: [
      {
        label: '条码',
        prop: 'barcode',
        row: true
      },
      {
        label: '名称',
        prop: 'name',
        type: 'select',
        props: {
          label: 'label',
          value: 'name'
        },
        cascader: ['model'],
        dicUrl: `exp/reagent/list?objectType=7`,
        rules: [
          {
            required: true,
            message: '请选择品牌',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '型号',
        prop: 'model',
        type: 'select',
        cascader: ['specifications'],
        props: {
          label: 'label',
          value: 'model'
        },
        dicUrl: `exp/reagent/list?objectType=7&name={{key}}`,
        rules: [
          {
            required: true,
            message: '请选择型号',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '规格',
        prop: 'specifications',
        type: 'select',
        cascader: ['brand'],
        props: {
          label: 'label',
          value: 'specifications'
        },
        dicUrl: `exp/reagent/list?objectType=7&name={{name}}&model={{model}}`,
        rules: [
          {
            required: true,
            message: '请选择规格',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '品牌',
        prop: 'brand',
        type: 'select',
        cascader: ['productionDate', 'code'],
        props: {
          label: 'brand',
          value: 'brand'
        },
        dicUrl: `exp/reagent/list?objectType=7&name={{name}}&model={{model}}&specifications={{specifications}}`,
        rules: [
          {
            required: true,
            message: '请选择品牌',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '生产日期',
        prop: 'productionDate',
        type: 'select',
        cascader: ['usePeriod', 'code'],
        props: {
          label: 'productionDate',
          value: 'productionDate'
        },
        dicUrl: `exp/reagent/list?objectType=7&name={{name}}&model={{model}}&specifications={{specifications}}&brand={{brand}}`,
        rules: [
          {
            required: false,
            message: '请选择生产日期',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '使用期限',
        prop: 'usePeriod',
        type: 'select',
        props: {
          label: 'usePeriod',
          value: 'usePeriod'
        },
        dicUrl: `exp/reagent/list?objectType=7&name={{name}}&model={{model}}&specifications={{specifications}}&brand={{brand}}&productionDate={{productionDate}}`
      },
      {
        label: '编号',
        prop: 'code',
        rules: [{
          required: true,
          message: '请输入编号'
        }]
      },
      {
        label: '用量',
        prop: 'amount',
        rules: [{
          required: true,
          message: '请输入用量'
        }]
      }]
  },
  36: {
    name: '药品母液',
    submitBtn: false,
    emptyBtn: false,
    column: [
      {
        label: '条码',
        prop: 'barcode',
        row: true
      },
      {
        label: '名称',
        prop: 'name',
        type: 'select',
        props: {
          label: 'label',
          value: 'name'
        },
        cascader: ['model'],
        dicUrl: `exp/reagent/list?objectType=36`,
        rules: [
          {
            required: true,
            message: '请选择品牌',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '型号',
        prop: 'model',
        type: 'select',
        cascader: ['specifications'],
        props: {
          label: 'label',
          value: 'model'
        },
        dicUrl: `exp/reagent/list?objectType=36&name={{key}}`,
        rules: [
          {
            required: true,
            message: '请选择型号',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '规格',
        prop: 'specifications',
        type: 'select',
        cascader: ['brand'],
        props: {
          label: 'label',
          value: 'specifications'
        },
        dicUrl: `exp/reagent/list?objectType=36&name={{name}}&model={{model}}`,
        rules: [
          {
            required: true,
            message: '请选择规格',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '品牌',
        prop: 'brand',
        type: 'select',
        cascader: ['productionDate', 'code'],
        props: {
          label: 'brand',
          value: 'brand'
        },
        dicUrl: `exp/reagent/list?objectType=36&name={{name}}&model={{model}}&specifications={{specifications}}`,
        rules: [
          {
            required: true,
            message: '请选择品牌',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '生产日期',
        prop: 'productionDate',
        type: 'select',
        cascader: ['usePeriod', 'code'],
        props: {
          label: 'productionDate',
          value: 'productionDate'
        },
        dicUrl: `exp/reagent/list?objectType=36&name={{name}}&model={{model}}&specifications={{specifications}}&brand={{brand}}`,
        rules: [
          {
            required: false,
            message: '请选择生产日期',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '使用期限',
        prop: 'usePeriod',
        type: 'select',
        props: {
          label: 'usePeriod',
          value: 'usePeriod'
        },
        dicUrl: `exp/reagent/list?objectType=36&name={{name}}&model={{model}}&specifications={{specifications}}&brand={{brand}}&productionDate={{productionDate}}`
      },
      {
        label: '编号',
        prop: 'code',
        rules: [{
          required: true,
          message: '请输入编号'
        }]
      },
      {
        label: '用量',
        prop: 'amount',
        rules: [{
          required: true,
          message: '请输入用量'
        }]
      }]
  },
  44: {
    name: '样本保存液',
    submitBtn: false,
    emptyBtn: false,
    column: [
      {
        label: '条码',
        prop: 'barcode',
        row: true
      },
      {
        label: '名称',
        prop: 'name',
        type: 'select',
        props: {
          label: 'label',
          value: 'name'
        },
        cascader: ['model'],
        dicUrl: `exp/reagent/list?objectType=44`,
        rules: [
          {
            required: true,
            message: '请选择品牌',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '型号',
        prop: 'model',
        type: 'select',
        cascader: ['specifications'],
        props: {
          label: 'label',
          value: 'model'
        },
        dicUrl: `exp/reagent/list?objectType=44&name={{key}}`,
        rules: [
          {
            required: true,
            message: '请选择型号',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '规格',
        prop: 'specifications',
        type: 'select',
        cascader: ['brand'],
        props: {
          label: 'label',
          value: 'specifications'
        },
        dicUrl: `exp/reagent/list?objectType=44&name={{name}}&model={{model}}`,
        rules: [
          {
            required: true,
            message: '请选择规格',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '品牌',
        prop: 'brand',
        type: 'select',
        cascader: ['productionDate', 'code'],
        props: {
          label: 'brand',
          value: 'brand'
        },
        dicUrl: `exp/reagent/list?objectType=44&name={{name}}&model={{model}}&specifications={{specifications}}`,
        rules: [
          {
            required: true,
            message: '请选择品牌',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '生产日期',
        prop: 'productionDate',
        type: 'select',
        cascader: ['usePeriod', 'code'],
        props: {
          label: 'productionDate',
          value: 'productionDate'
        },
        dicUrl: `exp/reagent/list?objectType=44&name={{name}}&model={{model}}&specifications={{specifications}}&brand={{brand}}`,
        rules: [
          {
            required: false,
            message: '请选择生产日期',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '使用期限',
        prop: 'usePeriod',
        type: 'select',
        props: {
          label: 'usePeriod',
          value: 'usePeriod'
        },
        dicUrl: `exp/reagent/list?objectType=44&name={{name}}&model={{model}}&specifications={{specifications}}&brand={{brand}}&productionDate={{productionDate}}`
      },
      {
        label: '编号',
        prop: 'code',
        rules: [{
          required: true,
          message: '请输入编号'
        }]
      },
      {
        label: '用量',
        prop: 'amount',
        rules: [{
          required: true,
          message: '请输入用量'
        }]
      }]
  },
  45: {
    name: '细胞冻存液',
    submitBtn: false,
    emptyBtn: false,
    column: [
      {
        label: '条码',
        prop: 'barcode',
        row: true
      },
      {
        label: '名称',
        prop: 'name',
        type: 'select',
        props: {
          label: 'label',
          value: 'name'
        },
        cascader: ['model'],
        dicUrl: `exp/reagent/list?objectType=45`,
        rules: [
          {
            required: true,
            message: '请选择品牌',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '型号',
        prop: 'model',
        type: 'select',
        cascader: ['specifications'],
        props: {
          label: 'label',
          value: 'model'
        },
        dicUrl: `exp/reagent/list?objectType=45&name={{key}}`,
        rules: [
          {
            required: true,
            message: '请选择型号',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '规格',
        prop: 'specifications',
        type: 'select',
        cascader: ['brand'],
        props: {
          label: 'label',
          value: 'specifications'
        },
        dicUrl: `exp/reagent/list?objectType=45&name={{name}}&model={{model}}`,
        rules: [
          {
            required: true,
            message: '请选择规格',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '品牌',
        prop: 'brand',
        type: 'select',
        cascader: ['productionDate', 'code'],
        props: {
          label: 'brand',
          value: 'brand'
        },
        dicUrl: `exp/reagent/list?objectType=45&name={{name}}&model={{model}}&specifications={{specifications}}`,
        rules: [
          {
            required: true,
            message: '请选择品牌',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '生产日期',
        prop: 'productionDate',
        type: 'select',
        cascader: ['usePeriod', 'code'],
        props: {
          label: 'productionDate',
          value: 'productionDate'
        },
        dicUrl: `exp/reagent/list?objectType=45&name={{name}}&model={{model}}&specifications={{specifications}}&brand={{brand}}`,
        rules: [
          {
            required: false,
            message: '请选择生产日期',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '使用期限',
        prop: 'usePeriod',
        type: 'select',
        props: {
          label: 'usePeriod',
          value: 'usePeriod'
        },
        dicUrl: `exp/reagent/list?objectType=45&name={{name}}&model={{model}}&specifications={{specifications}}&brand={{brand}}&productionDate={{productionDate}}`
      },
      {
        label: '编号',
        prop: 'code',
        rules: [{
          required: true,
          message: '请输入编号'
        }]
      },
      {
        label: '用量',
        prop: 'amount',
        rules: [{
          required: true,
          message: '请输入用量'
        }]
      }]
  },
  46: {
    name: '加药培养基',
    submitBtn: false,
    emptyBtn: false,
    column: [
      {
        label: '条码',
        prop: 'barcode',
        row: true
      },
      {
        label: '名称',
        prop: 'name',
        type: 'select',
        props: {
          label: 'label',
          value: 'name'
        },
        cascader: ['model'],
        dicUrl: `exp/reagent/list?objectType=46`,
        rules: [
          {
            required: true,
            message: '请选择品牌',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '型号',
        prop: 'model',
        type: 'select',
        cascader: ['specifications'],
        props: {
          label: 'label',
          value: 'model'
        },
        dicUrl: `exp/reagent/list?objectType=46&name={{key}}`,
        rules: [
          {
            required: true,
            message: '请选择型号',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '规格',
        prop: 'specifications',
        type: 'select',
        cascader: ['brand'],
        props: {
          label: 'label',
          value: 'specifications'
        },
        dicUrl: `exp/reagent/list?objectType=46&name={{name}}&model={{model}}`,
        rules: [
          {
            required: true,
            message: '请选择规格',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '品牌',
        prop: 'brand',
        type: 'select',
        cascader: ['productionDate', 'code'],
        props: {
          label: 'brand',
          value: 'brand'
        },
        dicUrl: `exp/reagent/list?objectType=46&name={{name}}&model={{model}}&specifications={{specifications}}`,
        rules: [
          {
            required: true,
            message: '请选择品牌',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '生产日期',
        prop: 'productionDate',
        type: 'select',
        cascader: ['usePeriod', 'code'],
        props: {
          label: 'productionDate',
          value: 'productionDate'
        },
        dicUrl: `exp/reagent/list?objectType=46&name={{name}}&model={{model}}&specifications={{specifications}}&brand={{brand}}`,
        rules: [
          {
            required: false,
            message: '请选择生产日期',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '使用期限',
        prop: 'usePeriod',
        type: 'select',
        props: {
          label: 'usePeriod',
          value: 'usePeriod'
        },
        dicUrl: `exp/reagent/list?objectType=46&name={{name}}&model={{model}}&specifications={{specifications}}&brand={{brand}}&productionDate={{productionDate}}`
      },
      {
        label: '编号',
        prop: 'code',
        rules: [{
          required: true,
          message: '请输入编号'
        }]
      },
      {
        label: '用量',
        prop: 'amount',
        rules: [{
          required: true,
          message: '请输入用量'
        }]
      }]
  },
  47: {
    name: 'Matrigel胶',
    submitBtn: false,
    emptyBtn: false,
    column: [
      {
        label: '条码',
        prop: 'barcode',
        row: true
      },
      {
        label: '名称',
        prop: 'name',
        type: 'select',
        props: {
          label: 'label',
          value: 'name'
        },
        cascader: ['model'],
        dicUrl: `exp/reagent/list?objectType=47`,
        rules: [
          {
            required: true,
            message: '请选择品牌',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '型号',
        prop: 'model',
        type: 'select',
        cascader: ['specifications'],
        props: {
          label: 'label',
          value: 'model'
        },
        dicUrl: `exp/reagent/list?objectType=47&name={{key}}`,
        rules: [
          {
            required: true,
            message: '请选择型号',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '规格',
        prop: 'specifications',
        type: 'select',
        cascader: ['brand'],
        props: {
          label: 'label',
          value: 'specifications'
        },
        dicUrl: `exp/reagent/list?objectType=47&name={{name}}&model={{model}}`,
        rules: [
          {
            required: true,
            message: '请选择规格',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '品牌',
        prop: 'brand',
        type: 'select',
        cascader: ['productionDate', 'code'],
        props: {
          label: 'brand',
          value: 'brand'
        },
        dicUrl: `exp/reagent/list?objectType=47&name={{name}}&model={{model}}&specifications={{specifications}}`,
        rules: [
          {
            required: true,
            message: '请选择品牌',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '生产日期',
        prop: 'productionDate',
        type: 'select',
        cascader: ['usePeriod', 'code'],
        props: {
          label: 'productionDate',
          value: 'productionDate'
        },
        dicUrl: `exp/reagent/list?objectType=47&name={{name}}&model={{model}}&specifications={{specifications}}&brand={{brand}}`,
        rules: [
          {
            required: false,
            message: '请选择生产日期',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '使用期限',
        prop: 'usePeriod',
        type: 'select',
        props: {
          label: 'usePeriod',
          value: 'usePeriod'
        },
        dicUrl: `exp/reagent/list?objectType=47&name={{name}}&model={{model}}&specifications={{specifications}}&brand={{brand}}&productionDate={{productionDate}}`
      },
      {
        label: '编号',
        prop: 'code',
        rules: [{
          required: true,
          message: '请输入编号'
        }]
      },
      {
        label: '用量',
        prop: 'amount',
        rules: [{
          required: true,
          message: '请输入用量'
        }]
      }]
  },
  8: {
    name: '离心管',
    submitBtn: false,
    emptyBtn: false,
    column: [
      {
        label: '条码',
        prop: 'barcode',
        row: true
      },
      {
        label: '名称',
        prop: 'name',
        type: 'select',
        props: {
          label: 'label',
          value: 'name'
        },
        cascader: ['model'],
        dicUrl: `exp/consumables/list?objectType=8`,
        rules: [
          {
            required: true,
            message: '请选择品牌',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '型号',
        prop: 'model',
        type: 'select',
        cascader: ['specifications'],
        props: {
          label: 'label',
          value: 'model'
        },
        dicUrl: `exp/consumables/list?objectType=8&name={{key}}`,
        rules: [
          {
            required: true,
            message: '请选择型号',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '规格',
        prop: 'specifications',
        type: 'select',
        cascader: ['brand'],
        props: {
          label: 'label',
          value: 'specifications'
        },
        dicUrl: `exp/consumables/list?objectType=8&name={{name}}&model={{model}}`,
        rules: [
          {
            required: true,
            message: '请选择规格',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '品牌',
        prop: 'brand',
        type: 'select',
        cascader: ['productionDate', 'code'],
        props: {
          label: 'brand',
          value: 'brand'
        },
        dicUrl: `exp/consumables/list?objectType=8&name={{name}}&model={{model}}&specifications={{specifications}}`,
        rules: [
          {
            required: true,
            message: '请选择品牌',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '生产日期',
        prop: 'productionDate',
        type: 'select',
        cascader: ['usePeriod', 'code'],
        props: {
          label: 'productionDate',
          value: 'productionDate'
        },
        dicUrl: `exp/consumables/list?objectType=8&name={{name}}&model={{model}}&specifications={{specifications}}&brand={{brand}}`,
        rules: [
          {
            required: false,
            message: '请选择生产日期',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '使用期限',
        prop: 'usePeriod',
        type: 'select',
        props: {
          label: 'usePeriod',
          value: 'usePeriod'
        },
        dicUrl: `exp/consumables/list?objectType=8&name={{name}}&model={{model}}&specifications={{specifications}}&brand={{brand}}&productionDate={{productionDate}}`
      },
      {
        label: '编号',
        prop: 'code',
        rules: [{
          required: true,
          message: '请输入编号'
        }]
      },
      {
        label: '数量',
        prop: 'amount',
        rules: [{
          required: true,
          message: '请输入数量'
        }]
      }]
  },
  9: {
    name: '细胞培养板',
    submitBtn: false,
    emptyBtn: false,
    column: [
      {
        label: '条码',
        prop: 'barcode',
        row: true
      },
      {
        label: '名称',
        prop: 'name',
        type: 'select',
        props: {
          label: 'label',
          value: 'name'
        },
        cascader: ['model'],
        dicUrl: `exp/consumables/list?objectType=9`,
        rules: [
          {
            required: true,
            message: '请选择品牌',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '型号',
        prop: 'model',
        type: 'select',
        cascader: ['specifications'],
        props: {
          label: 'label',
          value: 'model'
        },
        dicUrl: `exp/consumables/list?objectType=9&name={{key}}`,
        rules: [
          {
            required: true,
            message: '请选择型号',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '规格',
        prop: 'specifications',
        type: 'select',
        cascader: ['brand'],
        props: {
          label: 'label',
          value: 'specifications'
        },
        dicUrl: `exp/consumables/list?objectType=9&name={{name}}&model={{model}}`,
        rules: [
          {
            required: true,
            message: '请选择规格',
            trigger: 'blur'
          }
        ],
        control: (val, form) => {
          option[9].column[8].params.hole = form.$specifications
          return {
            tube: {
              params: {
                hole: form.$specifications
              },
              hole: form.$specifications
            }
          }
        },
        change: (val) => {
          console.log('change sp=======', val)
        }
      },
      {
        label: '品牌',
        prop: 'brand',
        type: 'select',
        cascader: ['productionDate', 'code'],
        props: {
          label: 'brand',
          value: 'brand'
        },
        dicUrl: `exp/consumables/list?objectType=9&name={{name}}&model={{model}}&specifications={{specifications}}`,
        rules: [
          {
            required: true,
            message: '请选择品牌',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '生产日期',
        prop: 'productionDate',
        type: 'select',
        cascader: ['usePeriod', 'code'],
        props: {
          label: 'productionDate',
          value: 'productionDate'
        },
        dicUrl: `exp/consumables/list?objectType=9&name={{name}}&model={{model}}&specifications={{specifications}}&brand={{brand}}`,
        rules: [
          {
            required: false,
            message: '请选择生产日期',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '使用期限',
        prop: 'usePeriod',
        type: 'select',
        props: {
          label: 'usePeriod',
          value: 'usePeriod'
        },
        dicUrl: `exp/consumables/list?objectType=9&name={{name}}&model={{model}}&specifications={{specifications}}&brand={{brand}}&productionDate={{productionDate}}`
      },
      {
        label: '编号',
        prop: 'code',
        rules: [{
          required: true,
          message: '请输入编号'
        }]
      },
      {
        label: '铺板',
        prop: 'tube',
        span: 24,
        hole: 24,
        params: {
          hole: 24
        }
      }]
  },
  10: {
    name: '试剂盒',
    submitBtn: false,
    emptyBtn: false,
    column: [
      {
        label: '条码',
        prop: 'barcode',
        row: true
      },
      {
        label: '名称',
        prop: 'name',
        type: 'select',
        props: {
          label: 'label',
          value: 'name'
        },
        cascader: ['model'],
        dicUrl: `exp/consumables/list?objectType=10`,
        rules: [
          {
            required: true,
            message: '请选择品牌',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '型号',
        prop: 'model',
        type: 'select',
        cascader: ['specifications'],
        props: {
          label: 'label',
          value: 'model'
        },
        dicUrl: `exp/consumables/list?objectType=10&name={{key}}`,
        rules: [
          {
            required: true,
            message: '请选择型号',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '规格',
        prop: 'specifications',
        type: 'select',
        cascader: ['brand'],
        props: {
          label: 'label',
          value: 'specifications'
        },
        dicUrl: `exp/consumables/list?objectType=10&name={{name}}&model={{model}}`,
        rules: [
          {
            required: true,
            message: '请选择规格',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '品牌',
        prop: 'brand',
        type: 'select',
        cascader: ['productionDate', 'code'],
        props: {
          label: 'brand',
          value: 'brand'
        },
        dicUrl: `exp/consumables/list?objectType=10&name={{name}}&model={{model}}&specifications={{specifications}}`,
        rules: [
          {
            required: true,
            message: '请选择品牌',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '生产日期',
        prop: 'productionDate',
        type: 'select',
        cascader: ['usePeriod', 'code'],
        props: {
          label: 'productionDate',
          value: 'productionDate'
        },
        dicUrl: `exp/consumables/list?objectType=10&name={{name}}&model={{model}}&specifications={{specifications}}&brand={{brand}}`,
        rules: [
          {
            required: false,
            message: '请选择生产日期',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '使用期限',
        prop: 'usePeriod',
        type: 'select',
        props: {
          label: 'usePeriod',
          value: 'usePeriod'
        },
        dicUrl: `exp/consumables/list?objectType=10&name={{name}}&model={{model}}&specifications={{specifications}}&brand={{brand}}&productionDate={{productionDate}}`
      },
      {
        label: '编号',
        prop: 'code',
        rules: [{
          required: true,
          message: '请输入编号'
        }]
      },
      {
        label: '数量',
        prop: 'amount',
        rules: [{
          required: true,
          message: '请输入数量'
        }]
      }]
  },
  11: {
    name: '细胞冻存管',
    submitBtn: false,
    emptyBtn: false,
    column: [
      {
        label: '条码',
        prop: 'barcode',
        row: true
      },
      {
        label: '名称',
        prop: 'name',
        type: 'select',
        props: {
          label: 'label',
          value: 'name'
        },
        cascader: ['model'],
        dicUrl: `exp/consumables/list?objectType=11`,
        rules: [
          {
            required: true,
            message: '请选择品牌',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '型号',
        prop: 'model',
        type: 'select',
        cascader: ['specifications'],
        props: {
          label: 'label',
          value: 'model'
        },
        dicUrl: `exp/consumables/list?objectType=11&name={{key}}`,
        rules: [
          {
            required: true,
            message: '请选择型号',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '规格',
        prop: 'specifications',
        type: 'select',
        cascader: ['brand'],
        props: {
          label: 'label',
          value: 'specifications'
        },
        dicUrl: `exp/consumables/list?objectType=11&name={{name}}&model={{model}}`,
        rules: [
          {
            required: true,
            message: '请选择规格',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '品牌',
        prop: 'brand',
        type: 'select',
        cascader: ['productionDate', 'code'],
        props: {
          label: 'brand',
          value: 'brand'
        },
        dicUrl: `exp/consumables/list?objectType=11&name={{name}}&model={{model}}&specifications={{specifications}}`,
        rules: [
          {
            required: true,
            message: '请选择品牌',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '生产日期',
        prop: 'productionDate',
        type: 'select',
        cascader: ['usePeriod', 'code'],
        props: {
          label: 'productionDate',
          value: 'productionDate'
        },
        dicUrl: `exp/consumables/list?objectType=11&name={{name}}&model={{model}}&specifications={{specifications}}&brand={{brand}}`,
        rules: [
          {
            required: false,
            message: '请选择生产日期',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '使用期限',
        prop: 'usePeriod',
        type: 'select',
        props: {
          label: 'usePeriod',
          value: 'usePeriod'
        },
        dicUrl: `exp/consumables/list?objectType=11&name={{name}}&model={{model}}&specifications={{specifications}}&brand={{brand}}&productionDate={{productionDate}}`
      },
      {
        label: '编号',
        prop: 'code',
        rules: [{
          required: true,
          message: '请输入编号'
        }]
      },
      {
        label: '数量',
        prop: 'amount',
        rules: [{
          required: true,
          message: '请输入数量'
        }]
      },
      {
        label: '冻存管编号',
        labelWidth: 100,
        prop: 'freezePipeCodes',
        rules: [{
          required: true,
          message: '冻存管编号'
        }],
        tip: '多个冻存管以英文逗号分割'
      }
    ]
  },
  12: {
    name: '吸嘴盒',
    submitBtn: false,
    emptyBtn: false,
    column: [{
      label: '条码',
      prop: 'barcode',
      row: true
    },
    {
      label: '名称',
      prop: 'name',
      type: 'select',
      props: {
        label: 'label',
        value: 'name'
      },
      cascader: ['model'],
      dicUrl: `exp/consumables/list?objectType=12`,
      rules: [
        {
          required: true,
          message: '请选择品牌',
          trigger: 'blur'
        }
      ]
    },
    {
      label: '型号',
      prop: 'model',
      type: 'select',
      cascader: ['specifications'],
      props: {
        label: 'label',
        value: 'model'
      },
      dicUrl: `exp/consumables/list?objectType=12&name={{key}}`,
      rules: [
        {
          required: true,
          message: '请选择型号',
          trigger: 'blur'
        }
      ]
    },
    {
      label: '规格',
      prop: 'specifications',
      type: 'select',
      cascader: ['brand'],
      props: {
        label: 'label',
        value: 'specifications'
      },
      dicUrl: `exp/consumables/list?objectType=12&name={{name}}&model={{model}}`,
      rules: [
        {
          required: true,
          message: '请选择规格',
          trigger: 'blur'
        }
      ]
    },
    {
      label: '品牌',
      prop: 'brand',
      type: 'select',
      cascader: ['productionDate', 'code'],
      props: {
        label: 'brand',
        value: 'brand'
      },
      dicUrl: `exp/consumables/list?objectType=12&name={{name}}&model={{model}}&specifications={{specifications}}`,
      rules: [
        {
          required: true,
          message: '请选择品牌',
          trigger: 'blur'
        }
      ]
    },
    {
      label: '生产日期',
      prop: 'productionDate',
      type: 'select',
      cascader: ['usePeriod', 'code'],
      props: {
        label: 'productionDate',
        value: 'productionDate'
      },
      dicUrl: `exp/consumables/list?objectType=12&name={{name}}&model={{model}}&specifications={{specifications}}&brand={{brand}}`,
      rules: [
        {
          required: false,
          message: '请选择生产日期',
          trigger: 'blur'
        }
      ]
    },
    {
      label: '使用期限',
      prop: 'usePeriod',
      type: 'select',
      props: {
        label: 'usePeriod',
        value: 'usePeriod'
      },
      dicUrl: `exp/consumables/list?objectType=12&name={{name}}&model={{model}}&specifications={{specifications}}&brand={{brand}}&productionDate={{productionDate}}`
    },
    {
      label: '编号',
      prop: 'code',
      rules: [{
        required: true,
        message: '请输入编号'
      }]
    },
    {
      label: '数量',
      prop: 'amount',
      rules: [{
        required: true,
        message: '请输入数量'
      }]
    }]
  },
  13: {
    name: '盒装枪头(TIP头)',
    submitBtn: false,
    emptyBtn: false,
    column: [
      {
        label: '条码',
        prop: 'barcode',
        row: true
      },
      {
        label: '名称',
        prop: 'name',
        type: 'select',
        props: {
          label: 'label',
          value: 'name'
        },
        cascader: ['model'],
        dicUrl: `exp/consumables/list?objectType=13`,
        rules: [
          {
            required: true,
            message: '请选择品牌',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '型号',
        prop: 'model',
        type: 'select',
        cascader: ['specifications'],
        props: {
          label: 'label',
          value: 'model'
        },
        dicUrl: `exp/consumables/list?objectType=13&name={{key}}`,
        rules: [
          {
            required: true,
            message: '请选择型号',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '规格',
        prop: 'specifications',
        type: 'select',
        cascader: ['brand'],
        props: {
          label: 'label',
          value: 'specifications'
        },
        dicUrl: `exp/consumables/list?objectType=13&name={{name}}&model={{model}}`,
        rules: [
          {
            required: true,
            message: '请选择规格',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '品牌',
        prop: 'brand',
        type: 'select',
        cascader: ['productionDate', 'code'],
        props: {
          label: 'brand',
          value: 'brand'
        },
        dicUrl: `exp/consumables/list?objectType=13&name={{name}}&model={{model}}&specifications={{specifications}}`,
        rules: [
          {
            required: true,
            message: '请选择品牌',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '生产日期',
        prop: 'productionDate',
        type: 'select',
        cascader: ['usePeriod', 'code'],
        props: {
          label: 'productionDate',
          value: 'productionDate'
        },
        dicUrl: `exp/consumables/list?objectType=13&name={{name}}&model={{model}}&specifications={{specifications}}&brand={{brand}}`,
        rules: [
          {
            required: false,
            message: '请选择生产日期',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '使用期限',
        prop: 'usePeriod',
        type: 'select',
        props: {
          label: 'usePeriod',
          value: 'usePeriod'
        },
        dicUrl: `exp/consumables/list?objectType=13&name={{name}}&model={{model}}&specifications={{specifications}}&brand={{brand}}&productionDate={{productionDate}}`
      },
      {
        label: '编号',
        prop: 'code',
        rules: [{
          required: true,
          message: '请选择编号'
        }]
      },
      {
        label: '数量',
        prop: 'amount',
        rules: [{
          required: true,
          message: '请输入数量'
        }]
      }]
  },
  14: {
    name: '细胞培养皿',
    submitBtn: false,
    emptyBtn: false,
    column: [
      {
        label: '条码',
        prop: 'barcode',
        row: true
      },
      {
        label: '名称',
        prop: 'name',
        type: 'select',
        props: {
          label: 'label',
          value: 'name'
        },
        cascader: ['model'],
        dicUrl: `exp/consumables/list?objectType=14`,
        rules: [
          {
            required: true,
            message: '请选择品牌',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '型号',
        prop: 'model',
        type: 'select',
        cascader: ['specifications'],
        props: {
          label: 'label',
          value: 'model'
        },
        dicUrl: `exp/consumables/list?objectType=14&name={{key}}`,
        rules: [
          {
            required: true,
            message: '请选择型号',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '规格',
        prop: 'specifications',
        type: 'select',
        cascader: ['brand'],
        props: {
          label: 'label',
          value: 'specifications'
        },
        dicUrl: `exp/consumables/list?objectType=14&name={{name}}&model={{model}}`,
        rules: [
          {
            required: true,
            message: '请选择规格',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '品牌',
        prop: 'brand',
        type: 'select',
        cascader: ['productionDate', 'code'],
        props: {
          label: 'brand',
          value: 'brand'
        },
        dicUrl: `exp/consumables/list?objectType=14&name={{name}}&model={{model}}&specifications={{specifications}}`,
        rules: [
          {
            required: true,
            message: '请选择品牌',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '生产日期',
        prop: 'productionDate',
        type: 'select',
        cascader: ['usePeriod', 'code'],
        props: {
          label: 'productionDate',
          value: 'productionDate'
        },
        dicUrl: `exp/consumables/list?objectType=14&name={{name}}&model={{model}}&specifications={{specifications}}&brand={{brand}}`,
        rules: [
          {
            required: false,
            message: '请选择生产日期',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '使用期限',
        prop: 'usePeriod',
        type: 'select',
        props: {
          label: 'usePeriod',
          value: 'usePeriod'
        },
        dicUrl: `exp/consumables/list?objectType=14&name={{name}}&model={{model}}&specifications={{specifications}}&brand={{brand}}&productionDate={{productionDate}}`
      },
      {
        label: '编号',
        prop: 'code',
        rules: [{
          required: true,
          message: '请输入编号'
        }]
      },
      {
        label: '用量',
        prop: 'amount',
        rules: [{
          required: true,
          message: '请输入数量'
        }]
      }]
  },
  33: {
    name: '过滤网',
    submitBtn: false,
    emptyBtn: false,
    column: [
      {
        label: '条码',
        prop: 'barcode',
        row: true
      },
      {
        label: '名称',
        prop: 'name',
        type: 'select',
        props: {
          label: 'label',
          value: 'name'
        },
        cascader: ['model'],
        dicUrl: `exp/consumables/list?objectType=33`,
        rules: [
          {
            required: true,
            message: '请选择品牌',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '型号',
        prop: 'model',
        type: 'select',
        cascader: ['specifications'],
        props: {
          label: 'label',
          value: 'model'
        },
        dicUrl: `exp/consumables/list?objectType=33&name={{key}}`,
        rules: [
          {
            required: true,
            message: '请选择型号',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '规格',
        prop: 'specifications',
        type: 'select',
        cascader: ['brand'],
        props: {
          label: 'label',
          value: 'specifications'
        },
        dicUrl: `exp/consumables/list?objectType=33&name={{name}}&model={{model}}`,
        rules: [
          {
            required: true,
            message: '请选择规格',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '品牌',
        prop: 'brand',
        type: 'select',
        cascader: ['productionDate', 'code'],
        props: {
          label: 'brand',
          value: 'brand'
        },
        dicUrl: `exp/consumables/list?objectType=33&name={{name}}&model={{model}}&specifications={{specifications}}`,
        rules: [
          {
            required: true,
            message: '请选择品牌',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '生产日期',
        prop: 'productionDate',
        type: 'select',
        cascader: ['usePeriod', 'code'],
        props: {
          label: 'productionDate',
          value: 'productionDate'
        },
        dicUrl: `exp/consumables/list?objectType=33&name={{name}}&model={{model}}&specifications={{specifications}}&brand={{brand}}`,
        rules: [
          {
            required: false,
            message: '请选择生产日期',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '使用期限',
        prop: 'usePeriod',
        type: 'select',
        props: {
          label: 'usePeriod',
          value: 'usePeriod'
        },
        dicUrl: `exp/consumables/list?objectType=33&name={{name}}&model={{model}}&specifications={{specifications}}&brand={{brand}}&productionDate={{productionDate}}`
      },
      {
        label: '编号',
        prop: 'code',
        rules: [{
          required: true,
          message: '请输入编号'
        }]
      },
      {
        label: '数量',
        prop: 'amount',
        rules: [{
          required: true,
          message: '请输入数量'
        }]
      }]
  },
  41: {
    name: '试剂槽',
    submitBtn: false,
    emptyBtn: false,
    column: [
      {
        label: '条码',
        prop: 'barcode',
        row: true
      },
      {
        label: '名称',
        prop: 'name',
        type: 'select',
        props: {
          label: 'label',
          value: 'name'
        },
        cascader: ['model'],
        dicUrl: `exp/consumables/list?objectType=41`,
        rules: [
          {
            required: true,
            message: '请选择品牌',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '型号',
        prop: 'model',
        type: 'select',
        cascader: ['specifications'],
        props: {
          label: 'label',
          value: 'model'
        },
        dicUrl: `exp/consumables/list?objectType=41&name={{key}}`,
        rules: [
          {
            required: true,
            message: '请选择型号',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '规格',
        prop: 'specifications',
        type: 'select',
        cascader: ['brand'],
        props: {
          label: 'label',
          value: 'specifications'
        },
        dicUrl: `exp/consumables/list?objectType=41&name={{name}}&model={{model}}`,
        rules: [
          {
            required: true,
            message: '请选择规格',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '品牌',
        prop: 'brand',
        type: 'select',
        cascader: ['productionDate', 'code'],
        props: {
          label: 'brand',
          value: 'brand'
        },
        dicUrl: `exp/consumables/list?objectType=41&name={{name}}&model={{model}}&specifications={{specifications}}`,
        rules: [
          {
            required: true,
            message: '请选择品牌',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '生产日期',
        prop: 'productionDate',
        type: 'select',
        cascader: ['usePeriod', 'code'],
        props: {
          label: 'productionDate',
          value: 'productionDate'
        },
        dicUrl: `exp/consumables/list?objectType=41&name={{name}}&model={{model}}&specifications={{specifications}}&brand={{brand}}`,
        rules: [
          {
            required: false,
            message: '请选择生产日期',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '使用期限',
        prop: 'usePeriod',
        type: 'select',
        props: {
          label: 'usePeriod',
          value: 'usePeriod'
        },
        dicUrl: `exp/consumables/list?objectType=41&name={{name}}&model={{model}}&specifications={{specifications}}&brand={{brand}}&productionDate={{productionDate}}`
      },
      {
        label: '编号',
        prop: 'code',
        rules: [{
          required: true,
          message: '请输入编号'
        }]
      },
      {
        label: '数量',
        prop: 'amount',
        rules: [{
          required: true,
          message: '请输入数量'
        }]
      }]
  },
  48: {
    name: '巴氏吸管',
    submitBtn: false,
    emptyBtn: false,
    column: [
      {
        label: '条码',
        prop: 'barcode',
        row: true
      },
      {
        label: '名称',
        prop: 'name',
        type: 'select',
        props: {
          label: 'label',
          value: 'name'
        },
        cascader: ['model'],
        dicUrl: `exp/consumables/list?objectType=48`,
        rules: [
          {
            required: true,
            message: '请选择品牌',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '型号',
        prop: 'model',
        type: 'select',
        cascader: ['specifications'],
        props: {
          label: 'label',
          value: 'model'
        },
        dicUrl: `exp/consumables/list?objectType=48&name={{key}}`,
        rules: [
          {
            required: true,
            message: '请选择型号',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '规格',
        prop: 'specifications',
        type: 'select',
        cascader: ['brand'],
        props: {
          label: 'label',
          value: 'specifications'
        },
        dicUrl: `exp/consumables/list?objectType=48&name={{name}}&model={{model}}`,
        rules: [
          {
            required: true,
            message: '请选择规格',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '品牌',
        prop: 'brand',
        type: 'select',
        cascader: ['productionDate', 'code'],
        props: {
          label: 'brand',
          value: 'brand'
        },
        dicUrl: `exp/consumables/list?objectType=48&name={{name}}&model={{model}}&specifications={{specifications}}`,
        rules: [
          {
            required: true,
            message: '请选择品牌',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '生产日期',
        prop: 'productionDate',
        type: 'select',
        cascader: ['usePeriod', 'code'],
        props: {
          label: 'productionDate',
          value: 'productionDate'
        },
        dicUrl: `exp/consumables/list?objectType=48&name={{name}}&model={{model}}&specifications={{specifications}}&brand={{brand}}`,
        rules: [
          {
            required: false,
            message: '请选择生产日期',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '使用期限',
        prop: 'usePeriod',
        type: 'select',
        props: {
          label: 'usePeriod',
          value: 'usePeriod'
        },
        dicUrl: `exp/consumables/list?objectType=48&name={{name}}&model={{model}}&specifications={{specifications}}&brand={{brand}}&productionDate={{productionDate}}`
      },
      {
        label: '编号',
        prop: 'code',
        rules: [{
          required: true,
          message: '请输入编号'
        }]
      },
      {
        label: '数量',
        prop: 'amount',
        rules: [{
          required: true,
          message: '请输入数量'
        }]
      }]
  },
  49: {
    name: '程序降温盒',
    submitBtn: false,
    emptyBtn: false,
    column: [
      {
        label: '条码',
        prop: 'barcode',
        row: true
      },
      {
        label: '名称',
        prop: 'name',
        type: 'select',
        props: {
          label: 'label',
          value: 'name'
        },
        cascader: ['model'],
        dicUrl: `exp/consumables/list?objectType=49`,
        rules: [
          {
            required: true,
            message: '请选择品牌',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '型号',
        prop: 'model',
        type: 'select',
        cascader: ['specifications'],
        props: {
          label: 'label',
          value: 'model'
        },
        dicUrl: `exp/consumables/list?objectType=49&name={{key}}`,
        rules: [
          {
            required: true,
            message: '请选择型号',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '规格',
        prop: 'specifications',
        type: 'select',
        cascader: ['brand'],
        props: {
          label: 'label',
          value: 'specifications'
        },
        dicUrl: `exp/consumables/list?objectType=49&name={{name}}&model={{model}}`,
        rules: [
          {
            required: true,
            message: '请选择规格',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '品牌',
        prop: 'brand',
        type: 'select',
        cascader: ['productionDate', 'code'],
        props: {
          label: 'brand',
          value: 'brand'
        },
        dicUrl: `exp/consumables/list?objectType=49&name={{name}}&model={{model}}&specifications={{specifications}}`,
        rules: [
          {
            required: true,
            message: '请选择品牌',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '生产日期',
        prop: 'productionDate',
        type: 'select',
        cascader: ['usePeriod', 'code'],
        props: {
          label: 'productionDate',
          value: 'productionDate'
        },
        dicUrl: `exp/consumables/list?objectType=49&name={{name}}&model={{model}}&specifications={{specifications}}&brand={{brand}}`,
        rules: [
          {
            required: false,
            message: '请选择生产日期',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '使用期限',
        prop: 'usePeriod',
        type: 'select',
        props: {
          label: 'usePeriod',
          value: 'usePeriod'
        },
        dicUrl: `exp/consumables/list?objectType=49&name={{name}}&model={{model}}&specifications={{specifications}}&brand={{brand}}&productionDate={{productionDate}}`
      },
      {
        label: '编号',
        prop: 'code',
        rules: [{
          required: true,
          message: '请输入编号'
        }]
      },
      {
        label: '数量',
        prop: 'amount',
        rules: [{
          required: true,
          message: '请输入数量'
        }]
      }]
  },
  50: {
    name: '眼科剪',
    submitBtn: false,
    emptyBtn: false,
    column: [
      {
        label: '条码',
        prop: 'barcode',
        row: true
      },
      {
        label: '名称',
        prop: 'name',
        type: 'select',
        props: {
          label: 'label',
          value: 'name'
        },
        cascader: ['model'],
        dicUrl: `exp/consumables/list?objectType=50`,
        rules: [
          {
            required: true,
            message: '请选择品牌',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '型号',
        prop: 'model',
        type: 'select',
        cascader: ['specifications'],
        props: {
          label: 'label',
          value: 'model'
        },
        dicUrl: `exp/consumables/list?objectType=50&name={{key}}`,
        rules: [
          {
            required: true,
            message: '请选择型号',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '规格',
        prop: 'specifications',
        type: 'select',
        cascader: ['brand'],
        props: {
          label: 'label',
          value: 'specifications'
        },
        dicUrl: `exp/consumables/list?objectType=50&name={{name}}&model={{model}}`,
        rules: [
          {
            required: true,
            message: '请选择规格',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '品牌',
        prop: 'brand',
        type: 'select',
        cascader: ['productionDate', 'code'],
        props: {
          label: 'brand',
          value: 'brand'
        },
        dicUrl: `exp/consumables/list?objectType=50&name={{name}}&model={{model}}&specifications={{specifications}}`,
        rules: [
          {
            required: true,
            message: '请选择品牌',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '生产日期',
        prop: 'productionDate',
        type: 'select',
        cascader: ['usePeriod', 'code'],
        props: {
          label: 'productionDate',
          value: 'productionDate'
        },
        dicUrl: `exp/consumables/list?objectType=50&name={{name}}&model={{model}}&specifications={{specifications}}&brand={{brand}}`,
        rules: [
          {
            required: false,
            message: '请选择生产日期',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '使用期限',
        prop: 'usePeriod',
        type: 'select',
        props: {
          label: 'usePeriod',
          value: 'usePeriod'
        },
        dicUrl: `exp/consumables/list?objectType=50&name={{name}}&model={{model}}&specifications={{specifications}}&brand={{brand}}&productionDate={{productionDate}}`
      },
      {
        label: '编号',
        prop: 'code',
        rules: [{
          required: true,
          message: '请输入编号'
        }]
      },
      {
        label: '数量',
        prop: 'amount',
        rules: [{
          required: true,
          message: '请输入数量'
        }]
      }]
  },
  51: {
    name: '无菌镊子',
    submitBtn: false,
    emptyBtn: false,
    column: [
      {
        label: '条码',
        prop: 'barcode',
        row: true
      },
      {
        label: '名称',
        prop: 'name',
        type: 'select',
        props: {
          label: 'label',
          value: 'name'
        },
        cascader: ['model'],
        dicUrl: `exp/consumables/list?objectType=51`,
        rules: [
          {
            required: true,
            message: '请选择品牌',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '型号',
        prop: 'model',
        type: 'select',
        cascader: ['specifications'],
        props: {
          label: 'label',
          value: 'model'
        },
        dicUrl: `exp/consumables/list?objectType=51&name={{key}}`,
        rules: [
          {
            required: true,
            message: '请选择型号',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '规格',
        prop: 'specifications',
        type: 'select',
        cascader: ['brand'],
        props: {
          label: 'label',
          value: 'specifications'
        },
        dicUrl: `exp/consumables/list?objectType=51&name={{name}}&model={{model}}`,
        rules: [
          {
            required: true,
            message: '请选择规格',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '品牌',
        prop: 'brand',
        type: 'select',
        cascader: ['productionDate', 'code'],
        props: {
          label: 'brand',
          value: 'brand'
        },
        dicUrl: `exp/consumables/list?objectType=51&name={{name}}&model={{model}}&specifications={{specifications}}`,
        rules: [
          {
            required: true,
            message: '请选择品牌',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '生产日期',
        prop: 'productionDate',
        type: 'select',
        cascader: ['usePeriod', 'code'],
        props: {
          label: 'productionDate',
          value: 'productionDate'
        },
        dicUrl: `exp/consumables/list?objectType=51&name={{name}}&model={{model}}&specifications={{specifications}}&brand={{brand}}`,
        rules: [
          {
            required: false,
            message: '请选择生产日期',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '使用期限',
        prop: 'usePeriod',
        type: 'select',
        props: {
          label: 'usePeriod',
          value: 'usePeriod'
        },
        dicUrl: `exp/consumables/list?objectType=51&name={{name}}&model={{model}}&specifications={{specifications}}&brand={{brand}}&productionDate={{productionDate}}`
      },
      {
        label: '编号',
        prop: 'code',
        rules: [{
          required: true,
          message: '请输入编号'
        }]
      },
      {
        label: '数量',
        prop: 'amount',
        rules: [{
          required: true,
          message: '请输入数量'
        }]
      }]
  },
  15: {
    name: '离心机',
    submitBtn: false,
    emptyBtn: false,
    column: [
      {
        label: '条码',
        prop: 'barcode',
        row: true
      },
      {
        label: '名称',
        prop: 'name',
        type: 'select',
        props: {
          label: 'label',
          value: 'name'
        },
        cascader: ['model'],
        dicUrl: `exp/equipment/list?objectType=15`,
        rules: [
          {
            required: true,
            message: '请选择品牌',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '型号',
        prop: 'model',
        type: 'select',
        cascader: ['specifications'],
        props: {
          label: 'label',
          value: 'model'
        },
        dicUrl: `exp/equipment/list?objectType=15&name={{key}}`,
        rules: [
          {
            required: true,
            message: '请选择型号',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '规格',
        prop: 'specifications',
        type: 'select',
        cascader: ['brand'],
        props: {
          label: 'label',
          value: 'specifications'
        },
        dicUrl: `exp/equipment/list?objectType=15&name={{name}}&model={{model}}`,
        rules: [
          {
            required: true,
            message: '请选择规格',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '品牌',
        prop: 'brand',
        type: 'select',
        cascader: ['productionDate', 'code'],
        props: {
          label: 'brand',
          value: 'brand'
        },
        dicUrl: `exp/equipment/list?objectType=15&name={{name}}&model={{model}}&specifications={{specifications}}`,
        rules: [
          {
            required: true,
            message: '请选择品牌',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '生产日期',
        prop: 'productionDate',
        type: 'select',
        cascader: ['usePeriod', 'code'],
        props: {
          label: 'productionDate',
          value: 'productionDate'
        },
        dicUrl: `exp/equipment/list?objectType=15&name={{name}}&model={{model}}&specifications={{specifications}}&brand={{brand}}`,
        rules: [
          {
            required: false,
            message: '请选择生产日期',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '使用期限',
        prop: 'usePeriod',
        type: 'select',
        props: {
          label: 'usePeriod',
          value: 'usePeriod'
        },
        dicUrl: `exp/equipment/list?objectType=15&name={{name}}&model={{model}}&specifications={{specifications}}&brand={{brand}}&productionDate={{productionDate}}`
      },
      {
        label: '编号',
        prop: 'code',
        rules: [{
          required: true,
          message: '请输入编号'
        }]
      },
      {
        label: '转速',
        prop: 'speed',
        rules: [{
          required: true,
          message: '请输入转速'
        }]
      },
      {
        label: '时长',
        prop: 'times',
        rules: [{
          required: true,
          message: '请输入时长'
        }]
      },
      {
        label: '其他',
        type: 'textarea',
        prop: 'remarks'
      }]
  },
  16: {
    name: '冰箱',
    submitBtn: false,
    emptyBtn: false,
    column: [
      {
        label: '条码',
        prop: 'barcode',
        row: true
      },
      {
        label: '名称',
        prop: 'name',
        type: 'select',
        props: {
          label: 'label',
          value: 'name'
        },
        cascader: ['model'],
        dicUrl: `exp/equipment/list?objectType=16`,
        rules: [
          {
            required: true,
            message: '请选择名称',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '型号',
        prop: 'model',
        type: 'select',
        cascader: ['specifications'],
        props: {
          label: 'label',
          value: 'model'
        },
        dicUrl: `exp/equipment/list?objectType=16&name={{key}}`,
        rules: [
          {
            required: true,
            message: '请选择型号',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '规格',
        prop: 'specifications',
        type: 'select',
        cascader: ['brand'],
        props: {
          label: 'label',
          value: 'specifications'
        },
        dicUrl: `exp/equipment/list?objectType=16&name={{name}}&model={{model}}`,
        rules: [
          {
            required: true,
            message: '请选择规格',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '品牌',
        prop: 'brand',
        type: 'select',
        cascader: ['productionDate', 'code'],
        props: {
          label: 'brand',
          value: 'brand'
        },
        dicUrl: `exp/equipment/list?objectType=16&name={{name}}&model={{model}}&specifications={{specifications}}`,
        rules: [
          {
            required: true,
            message: '请选择品牌',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '生产日期',
        prop: 'productionDate',
        type: 'select',
        cascader: ['usePeriod', 'code'],
        props: {
          label: 'productionDate',
          value: 'productionDate'
        },
        dicUrl: `exp/equipment/list?objectType=16&name={{name}}&model={{model}}&specifications={{specifications}}&brand={{brand}}`,
        rules: [
          {
            required: false,
            message: '请选择生产日期',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '使用期限',
        prop: 'usePeriod',
        type: 'select',
        props: {
          label: 'usePeriod',
          value: 'usePeriod'
        },
        dicUrl: `exp/equipment/list?objectType=16&name={{name}}&model={{model}}&specifications={{specifications}}&brand={{brand}}&productionDate={{productionDate}}`
      },
      {
        label: '编号',
        prop: 'code',
        rules: [{
          required: true,
          message: '请输入编号'
        }]
      },
      {
        label: '冰箱层位',
        prop: 'location',
        type: 'select',
        props: {
          value: 'value',
          label: 'label'
        },
        rules: [{
          required: true,
          message: '请选择冰箱层位'
        }],
        dicUrl: '/sys/dict/code/bx_location'
      },
      {
        label: '冰箱温度',
        prop: 'temperature',
        rules: [{
          required: true,
          message: '请输入温度'
        }]
      },
      {
        label: '放入时间',
        prop: 'intoTime',
        type: 'datetime',
        format: 'yyyy-MM-dd HH:mm:ss',
        valueFormat: 'yyyy-MM-dd HH:mm:ss'
      },
      {
        label: '存放时长',
        prop: 'keepTime',
        rules: [{
          required: true,
          message: '请输入存放时长'
        }]
      },
      {
        label: '其他',
        type: 'textarea',
        prop: 'remarks'
      }
    ]
  },
  17: {
    name: '培养箱',
    submitBtn: false,
    emptyBtn: false,
    column: [
      {
        label: '条码',
        prop: 'barcode',
        row: true
      },
      {
        label: '名称',
        prop: 'name',
        type: 'select',
        props: {
          label: 'label',
          value: 'name'
        },
        cascader: ['model'],
        dicUrl: `exp/equipment/list?objectType=17`,
        rules: [
          {
            required: true,
            message: '请选择品牌',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '型号',
        prop: 'model',
        type: 'select',
        cascader: ['specifications'],
        props: {
          label: 'label',
          value: 'model'
        },
        dicUrl: `exp/equipment/list?objectType=17&name={{key}}`,
        rules: [
          {
            required: true,
            message: '请选择型号',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '规格',
        prop: 'specifications',
        type: 'select',
        cascader: ['brand'],
        props: {
          label: 'label',
          value: 'specifications'
        },
        dicUrl: `exp/equipment/list?objectType=17&name={{name}}&model={{model}}`,
        rules: [
          {
            required: true,
            message: '请选择规格',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '品牌',
        prop: 'brand',
        type: 'select',
        cascader: ['productionDate', 'code'],
        props: {
          label: 'brand',
          value: 'brand'
        },
        dicUrl: `exp/equipment/list?objectType=17&name={{name}}&model={{model}}&specifications={{specifications}}`,
        rules: [
          {
            required: true,
            message: '请选择品牌',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '生产日期',
        prop: 'productionDate',
        type: 'select',
        cascader: ['usePeriod', 'code'],
        props: {
          label: 'productionDate',
          value: 'productionDate'
        },
        dicUrl: `exp/equipment/list?objectType=17&name={{name}}&model={{model}}&specifications={{specifications}}&brand={{brand}}`,
        rules: [
          {
            required: false,
            message: '请选择生产日期',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '使用期限',
        prop: 'usePeriod',
        type: 'select',
        props: {
          label: 'usePeriod',
          value: 'usePeriod'
        },
        dicUrl: `exp/equipment/list?objectType=17&name={{name}}&model={{model}}&specifications={{specifications}}&brand={{brand}}&productionDate={{productionDate}}`
      },
      {
        label: '编号',
        prop: 'code',
        rules: [{
          required: true,
          message: '请输入培养箱编号'
        }],
        labelWidth: 100
      },
      {
        label: '培养箱层位',
        prop: 'location',
        type: 'select',
        props: {
          label: 'label',
          value: 'value'
        },
        dicUrl: '/sys/dict/code/pyx_location'
      },
      {
        label: '培养箱温度',
        prop: 'temperature',
        rules: [{
          required: true,
          message: '请输入温度'
        }],
        labelWidth: 100
      },
      {
        label: '放入时间',
        prop: 'intoTime',
        type: 'datetime',
        format: 'yyyy-MM-dd HH:mm:ss',
        valueFormat: 'yyyy-MM-dd HH:mm:ss',
        rules: [{
          required: true,
          message: '请选择放入时间'
        }]
      },
      {
        label: '存放时长',
        prop: 'keepTime',
        rules: [{
          required: false,
          message: '请输入存放时长'
        }],
        labelWidth: 100
      },
      {
        label: '结束时间',
        prop: 'endTime',
        type: 'datetime',
        format: 'yyyy-MM-dd HH:mm:ss',
        valueFormat: 'yyyy-MM-dd HH:mm:ss',
        rules: [{
          required: false,
          message: '请选择结束时间'
        }]
      },
      {
        label: '其他',
        type: 'textarea',
        prop: 'remarks'
      }]
  },
  18: {
    name: '液氮罐',
    submitBtn: false,
    emptyBtn: false,
    column: [
      {
        label: '条码',
        prop: 'barcode',
        row: true
      },
      {
        label: '名称',
        prop: 'name',
        type: 'select',
        props: {
          label: 'label',
          value: 'name'
        },
        cascader: ['model'],
        dicUrl: `exp/equipment/list?objectType=18`,
        rules: [
          {
            required: true,
            message: '请选择品牌',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '型号',
        prop: 'model',
        type: 'select',
        cascader: ['specifications'],
        props: {
          label: 'label',
          value: 'model'
        },
        dicUrl: `exp/equipment/list?objectType=18&name={{key}}`,
        rules: [
          {
            required: true,
            message: '请选择型号',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '规格',
        prop: 'specifications',
        type: 'select',
        cascader: ['brand'],
        props: {
          label: 'label',
          value: 'specifications'
        },
        dicUrl: `exp/equipment/list?objectType=18&name={{name}}&model={{model}}`,
        rules: [
          {
            required: true,
            message: '请选择规格',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '品牌',
        prop: 'brand',
        type: 'select',
        cascader: ['productionDate', 'code'],
        props: {
          label: 'brand',
          value: 'brand'
        },
        dicUrl: `exp/equipment/list?objectType=18&name={{name}}&model={{model}}&specifications={{specifications}}`,
        rules: [
          {
            required: true,
            message: '请选择品牌',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '生产日期',
        prop: 'productionDate',
        type: 'select',
        cascader: ['usePeriod', 'code'],
        props: {
          label: 'productionDate',
          value: 'productionDate'
        },
        dicUrl: `exp/equipment/list?objectType=18&name={{name}}&model={{model}}&specifications={{specifications}}&brand={{brand}}`,
        rules: [
          {
            required: false,
            message: '请选择生产日期',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '使用期限',
        prop: 'usePeriod',
        type: 'select',
        props: {
          label: 'usePeriod',
          value: 'usePeriod'
        },
        dicUrl: `exp/equipment/list?objectType=18&name={{name}}&model={{model}}&specifications={{specifications}}&brand={{brand}}&productionDate={{productionDate}}`
      },
      {
        labelWidth: 100,
        label: '编号',
        prop: 'code',
        rules: [{
          required: true,
          message: '请输入编号'
        }]
      },
      {
        label: '液氮罐提号',
        prop: 'nitrogenNo'
      },
      {
        labelWidth: 100,
        label: '液氮罐层位',
        prop: 'location',
        type: 'select',
        dicUrl: '/sys/dict/code/bx_location'
      },
      {
        label: '放入时间',
        prop: 'intoTime',
        type: 'datetime',
        format: 'yyyy-MM-dd HH:mm:ss',
        valueFormat: 'yyyy-MM-dd HH:mm:ss'
      },
      {
        labelWidth: 100,
        label: '其他',
        type: 'textarea',
        prop: 'remarks'
      }]
  },
  19: {
    name: '破碎仪',
    submitBtn: false,
    emptyBtn: false,
    labelWidth: 100,
    column: [
      {
        label: '条码',
        prop: 'barcode',
        row: true
      },
      {
        label: '名称',
        prop: 'name',
        type: 'select',
        props: {
          label: 'label',
          value: 'name'
        },
        cascader: ['model'],
        dicUrl: `exp/equipment/list?objectType=19`,
        rules: [
          {
            required: true,
            message: '请选择品牌',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '型号',
        prop: 'model',
        type: 'select',
        cascader: ['specifications'],
        props: {
          label: 'label',
          value: 'model'
        },
        dicUrl: `exp/equipment/list?objectType=19&name={{key}}`,
        rules: [
          {
            required: true,
            message: '请选择型号',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '规格',
        prop: 'specifications',
        type: 'select',
        cascader: ['brand'],
        props: {
          label: 'label',
          value: 'specifications'
        },
        dicUrl: `exp/equipment/list?objectType=19&name={{name}}&model={{model}}`,
        rules: [
          {
            required: true,
            message: '请选择规格',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '品牌',
        prop: 'brand',
        type: 'select',
        cascader: ['productionDate', 'code'],
        props: {
          label: 'brand',
          value: 'brand'
        },
        dicUrl: `exp/equipment/list?objectType=19&name={{name}}&model={{model}}&specifications={{specifications}}`,
        rules: [
          {
            required: true,
            message: '请选择品牌',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '生产日期',
        prop: 'productionDate',
        type: 'select',
        cascader: ['usePeriod', 'code'],
        props: {
          label: 'productionDate',
          value: 'productionDate'
        },
        dicUrl: `exp/equipment/list?objectType=19&name={{name}}&model={{model}}&specifications={{specifications}}&brand={{brand}}`,
        rules: [
          {
            required: false,
            message: '请选择生产日期',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '使用期限',
        prop: 'usePeriod',
        type: 'select',
        props: {
          label: 'usePeriod',
          value: 'usePeriod'
        },
        dicUrl: `exp/equipment/list?objectType=19&name={{name}}&model={{model}}&specifications={{specifications}}&brand={{brand}}&productionDate={{productionDate}}`
      },
      {
        label: '编号',
        prop: 'code',
        rules: [{
          required: true,
          message: '请输入编号'
        }]
      },
      {
        label: '破碎仪参数',
        prop: 'params',
        rules: [{
          required: true,
          message: '请输入破碎仪参数'
        }],
        labelWidth: 100
      },
      {
        label: '破碎时长',
        prop: 'crushDuration',
        rules: [{
          required: true,
          message: '请输入破碎时长'
        }],
        labelWidth: 100
      }]
  },
  20: {
    name: '水浴锅',
    submitBtn: false,
    emptyBtn: false,
    column: [
      {
        label: '条码',
        prop: 'barcode',
        row: true
      },
      {
        label: '名称',
        prop: 'name',
        type: 'select',
        props: {
          label: 'label',
          value: 'name'
        },
        cascader: ['model'],
        dicUrl: `exp/equipment/list?objectType=20`,
        rules: [
          {
            required: true,
            message: '请选择品牌',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '型号',
        prop: 'model',
        type: 'select',
        cascader: ['specifications'],
        props: {
          label: 'label',
          value: 'model'
        },
        dicUrl: `exp/equipment/list?objectType=20&name={{key}}`,
        rules: [
          {
            required: true,
            message: '请选择型号',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '规格',
        prop: 'specifications',
        type: 'select',
        cascader: ['brand'],
        props: {
          label: 'label',
          value: 'specifications'
        },
        dicUrl: `exp/equipment/list?objectType=20&name={{name}}&model={{model}}`,
        rules: [
          {
            required: true,
            message: '请选择规格',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '品牌',
        prop: 'brand',
        type: 'select',
        cascader: ['productionDate', 'code'],
        props: {
          label: 'brand',
          value: 'brand'
        },
        dicUrl: `exp/equipment/list?objectType=20&name={{name}}&model={{model}}&specifications={{specifications}}`,
        rules: [
          {
            required: true,
            message: '请选择品牌',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '生产日期',
        prop: 'productionDate',
        type: 'select',
        cascader: ['usePeriod', 'code'],
        props: {
          label: 'productionDate',
          value: 'productionDate'
        },
        dicUrl: `exp/equipment/list?objectType=20&name={{name}}&model={{model}}&specifications={{specifications}}&brand={{brand}}`,
        rules: [
          {
            required: false,
            message: '请选择生产日期',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '使用期限',
        prop: 'usePeriod',
        type: 'select',
        props: {
          label: 'usePeriod',
          value: 'usePeriod'
        },
        dicUrl: `exp/equipment/list?objectType=20&name={{name}}&model={{model}}&specifications={{specifications}}&brand={{brand}}&productionDate={{productionDate}}`
      },
      {
        label: '编号',
        prop: 'code',
        labelWidth: 90
      },
      {
        label: '水浴锅温度',
        prop: 'temperature',
        rules: [{
          required: true,
          message: '请输入温度'
        }],
        labelWidth: 100
      },
      {
        label: '放入时间',
        prop: 'intoTime',
        type: 'datetime',
        format: 'yyyy-MM-dd HH:mm:ss',
        valueFormat: 'yyyy-MM-dd HH:mm:ss',
        rules: [{
          required: true,
          message: '请选择放入时间'
        }]
      },
      {
        label: '存放时长',
        prop: 'keepTime',
        rules: [{
          required: true,
          message: '请输入存放时长'
        }],
        labelWidth: 100
      },
      {
        label: '其他',
        type: 'textarea',
        prop: 'remarks'
      }]
  },
  21: {
    name: '类器官鉴定类型',
    submitBtn: false,
    emptyBtn: false,
    column: [{
      label: '鉴定类型',
      prop: 'type',
      type: 'radio',
      value: '0',
      rules: [{
        required: true,
        message: '请选择类型'
      }],
      dicData: [{
        label: '基因测序',
        value: '0'
      }, {
        label: '组化鉴定',
        value: '1'
      }],
      control: (val, form) => {
        if (val === '0') {
          return {
            nums: {
              label: '第几代基因测序'
            }
          }
        } else {
          return {
            nums: {
              label: '第几代组化鉴定'
            }
          }
        }
      }
    },
    {
      label: '第几代基因测序',
      prop: 'nums',
      labelWidth: 150,
      maxlength: 200
    }]
  },
  22: {
    name: '类器官鉴定送样',
    submitBtn: false,
    emptyBtn: false,
    column: [{
      labelWidth: 150,
      label: '送样人',
      prop: 'sender',
      rules: [{
        required: true,
        message: '请输入送样人'
      }],
      maxlength: 200
    },
    {
      labelWidth: 150,
      label: '送样时间',
      prop: 'sentTime',
      type: 'datetime',
      format: 'yyyy-MM-dd HH:mm:ss',
      valueFormat: 'yyyy-MM-dd HH:mm:ss',
      rules: [{
        required: true,
        message: '请选择放入时间'
      }]
    },
    {
      labelWidth: 150,
      label: '送样前4X镜状态照片',
      prop: 'imgUrl',
      type: 'upload',
      loadText: '图片上传中，请稍等',
      span: 24,
      rules: [{
        required: false,
        message: '请上传送样前4X镜状态照片'
      }],
      propsHttp: {
        home: `${fileSaveUrl}`,
        res: 'data'
      },
      tip: '只能上传jpg/png文件',
      action: '/common/fileUpload',
      listType: 'picture-card'
    }]
  },
  23: {
    name: '类器官鉴定反馈',
    submitBtn: false,
    emptyBtn: false,
    column: [{
      labelWidth: 120,
      label: '组化Marker',
      prop: 'marker'
    },
    {
      labelWidth: 120,
      label: '反馈时间',
      prop: 'feedBackTime',
      type: 'datetime',
      format: 'yyyy-MM-dd HH:mm:ss',
      valueFormat: 'yyyy-MM-dd HH:mm:ss',
      rules: [{
        required: true,
        message: '请选择反馈时间'
      }]
    },
    {
      labelWidth: 120,
      label: '反馈文档',
      prop: 'doc',
      type: 'upload',
      loadText: '文件上传中，请稍等',
      row: true,
      span: 8,
      rules: [{
        required: false,
        message: '请上传反馈文档'
      }],
      propsHttp: {
        home: `${fileSaveUrl}`,
        res: 'data'
      },
      action: '/common/fileUpload',
      limit: 3,
      multiple: true
    },
    {
      labelWidth: 120,
      label: '鉴定是否成功',
      prop: 'isSuccess',
      type: 'radio',
      rules: [{
        required: true,
        message: '请选择鉴定是否成功'
      }],
      dicData: [{
        label: '否',
        value: 0
      }, {
        label: '是',
        value: 1
      }]
    }]
  },
  24: {
    name: '配药孔板',
    submitBtn: false,
    emptyBtn: false,
    column: [
      {
        label: '条码',
        prop: 'barcode',
        row: true
      },
      {
        label: '名称',
        prop: 'name',
        type: 'select',
        props: {
          label: 'label',
          value: 'name'
        },
        cascader: ['model'],
        dicUrl: `exp/consumables/list?objectType=24`,
        rules: [
          {
            required: true,
            message: '请选择品牌',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '型号',
        prop: 'model',
        type: 'select',
        cascader: ['specifications'],
        props: {
          label: 'label',
          value: 'model'
        },
        dicUrl: `exp/consumables/list?objectType=24&name={{key}}`,
        rules: [
          {
            required: true,
            message: '请选择型号',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '规格',
        prop: 'specifications',
        type: 'select',
        cascader: ['brand'],
        props: {
          label: 'label',
          value: 'specifications'
        },
        dicUrl: `exp/consumables/list?objectType=24&name={{name}}&model={{model}}`,
        rules: [
          {
            required: true,
            message: '请选择规格',
            trigger: 'blur'
          }
        ],
        control: (val, form) => {
          option[24].column[8].params.hole = form.$specifications
          return {
            tube: {
              params: {
                hole: form.$specifications
              },
              hole: form.$specifications
            }
          }
        }
      },
      {
        label: '品牌',
        prop: 'brand',
        type: 'select',
        cascader: ['productionDate', 'code'],
        props: {
          label: 'brand',
          value: 'brand'
        },
        dicUrl: `exp/consumables/list?objectType=24&name={{name}}&model={{model}}&specifications={{specifications}}`,
        rules: [
          {
            required: true,
            message: '请选择品牌',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '生产日期',
        prop: 'productionDate',
        type: 'select',
        cascader: ['usePeriod', 'code'],
        props: {
          label: 'productionDate',
          value: 'productionDate'
        },
        dicUrl: `exp/consumables/list?objectType=24&name={{name}}&model={{model}}&specifications={{specifications}}&brand={{brand}}`,
        rules: [
          {
            required: false,
            message: '请选择生产日期',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '使用期限',
        prop: 'usePeriod',
        type: 'select',
        props: {
          label: 'usePeriod',
          value: 'usePeriod'
        },
        dicUrl: `exp/consumables/list?objectType=24&name={{name}}&model={{model}}&specifications={{specifications}}&brand={{brand}}&productionDate={{productionDate}}`
      },
      {
        label: '编号',
        prop: 'code',
        rules: [{
          required: true,
          message: '请输入编号'
        }]
      },
      {
        label: '铺板',
        prop: 'tube',
        span: 24,
        params: {
          hole: 96
        }
      }]
  },
  43: {
    name: '加药铺板',
    submitBtn: false,
    emptyBtn: false,
    column: [
      // {
      //   label: '条码',
      //   prop: 'barcode',
      //   row: true
      // },
      // {
      //   label: '名称',
      //   prop: 'name',
      //   type: 'select',
      //   props: {
      //     label: 'label',
      //     value: 'name'
      //   },
      //   cascader: ['model'],
      //   dicUrl: `exp/consumables/list?objectType=43`,
      //   rules: [
      //     {
      //       required: true,
      //       message: '请选择品牌',
      //       trigger: 'blur'
      //     }
      //   ]
      // },
      // {
      //   label: '型号',
      //   prop: 'model',
      //   type: 'select',
      //   cascader: ['specifications'],
      //   props: {
      //     label: 'label',
      //     value: 'model'
      //   },
      //   dicUrl: `exp/consumables/list?objectType=43&name={{key}}`,
      //   rules: [
      //     {
      //       required: true,
      //       message: '请选择型号',
      //       trigger: 'blur'
      //     }
      //   ]
      // },
      // {
      //   label: '规格',
      //   prop: 'specifications',
      //   type: 'select',
      //   cascader: ['brand'],
      //   props: {
      //     label: 'label',
      //     value: 'specifications'
      //   },
      //   dicUrl: `exp/consumables/list?objectType=43&name={{name}}&model={{model}}`,
      //   rules: [
      //     {
      //       required: true,
      //       message: '请选择规格',
      //       trigger: 'blur'
      //     }
      //   ],
      //   control: (val, form) => {
      //     option[43].column[8].params.hole = form.$specifications
      //     return {
      //       tube: {
      //         params: {
      //           hole: form.$specifications
      //         },
      //         hole: form.$specifications
      //       }
      //     }
      //   }
      // },
      // {
      //   label: '品牌',
      //   prop: 'brand',
      //   type: 'select',
      //   cascader: ['productionDate', 'code'],
      //   props: {
      //     label: 'brand',
      //     value: 'brand'
      //   },
      //   dicUrl: `exp/consumables/list?objectType=43&name={{name}}&model={{model}}&specifications={{specifications}}`,
      //   rules: [
      //     {
      //       required: true,
      //       message: '请选择品牌',
      //       trigger: 'blur'
      //     }
      //   ]
      // },
      // {
      //   label: '生产日期',
      //   prop: 'productionDate',
      //   type: 'select',
      //   cascader: ['usePeriod', 'code'],
      //   props: {
      //     label: 'productionDate',
      //     value: 'productionDate'
      //   },
      //   dicUrl: `exp/consumables/list?objectType=43&name={{name}}&model={{model}}&specifications={{specifications}}&brand={{brand}}`,
      //   rules: [
      //     {
      //       required: false,
      //       message: '请选择生产日期',
      //       trigger: 'blur'
      //     }
      //   ]
      // },
      // {
      //   label: '使用期限',
      //   prop: 'usePeriod',
      //   type: 'select',
      //   props: {
      //     label: 'usePeriod',
      //     value: 'usePeriod'
      //   },
      //   dicUrl: `exp/consumables/list?objectType=43&name={{name}}&model={{model}}&specifications={{specifications}}&brand={{brand}}&productionDate={{productionDate}}`
      // },
      // {
      //   label: '编号',
      //   prop: 'code',
      //   rules: [{
      //     required: true,
      //     message: '请输入编号'
      //   }]
      // },
      {
        label: '铺板',
        prop: 'drugTube',
        control: (val, form) => {
          return {
            drugTube: {
              hole: form.$specifications
            }
          }
        },
        span: 24
      }]
  },
  26: {
    name: '药敏生长照片：',
    submitBtn: false,
    emptyBtn: false,
    column: [{
      labelWidth: 100,
      label: '药敏生长照片',
      prop: 'tubeDay',
      span: 24,
      params: {
        hole: 24
      }
    }]
  },
  28: {
    name: '样品处理完照片',
    submitBtn: false,
    emptyBtn: false,
    column: [{
      label: '样品处理感受',
      prop: 'feelings',
      type: 'textarea',
      labelWidth: 120
    },
    {
      label: '样品处理完照片',
      prop: 'imgUrl',
      type: 'upload',
      loadText: '图片上传中，请稍等',
      span: 24,
      rules: [{
        required: false,
        message: '请上传样品处理完照片'
      }],
      propsHttp: {
        home: `${fileSaveUrl}`,
        res: 'data'
      },
      tip: '只能上传jpg/png文件',
      action: '/common/fileUpload',
      listType: 'picture-img',
      labelWidth: 120
    }]
  },
  29: {
    name: '培养照片：',
    submitBtn: false,
    emptyBtn: false,
    column: [{
      labelWidth: 100,
      label: '培养照片',
      prop: 'tubeDay',
      span: 24,
      params: {
        hole: 24
      }
    }]
  },
  34: {
    name: '培养生长结果',
    submitBtn: false,
    emptyBtn: false,
    column: [{
      labelWidth: 120,
      label: '生长结果评价',
      prop: 'comments',
      type: 'textarea'
    },
    {
      labelWidth: 120,
      label: '是否有效样本',
      prop: 'isValid',
      type: 'select',
      rules: [{
        required: true,
        message: '请选择是否有效样本'
      }],
      dicData: [{
        label: '否',
        value: 0
      }, {
        label: '是',
        value: 1
      }]
    },
    {
      labelWidth: 120,
      label: '是否培养成功',
      prop: 'isSuccess',
      type: 'select',
      rules: [{
        required: true,
        message: '请选择是否培养成功'
      }],
      dicData: [{
        label: '否',
        value: 0
      }, {
        label: '是',
        value: 1
      }]
    },
    {
      label: '类器官建模照片',
      prop: 'modelingImgUrl',
      type: 'upload',
      loadText: '图片上传中，请稍等',
      span: 24,
      rules: [{
        required: false,
        message: '请上传类器官建模照片'
      }],
      tip: '只能上传jpg/png文件',
      propsHttp: {
        res: 'data',
        name: 'name',
        url: 'url'
      },
      action: '/common/oss/fileUpload',
      listType: 'picture-img',
      labelWidth: 120
    },
    {
      label: '特写照片',
      prop: 'featureImgUrl',
      type: 'upload',
      loadText: '图片上传中，请稍等',
      span: 24,
      rules: [{
        required: false,
        message: '请上传特写照片'
      }],
      tip: '只能上传jpg/png文件',
      propsHttp: {
        res: 'data',
        name: 'name',
        url: 'url'
      },
      action: '/common/oss/fileUpload',
      listType: 'picture-img',
      labelWidth: 120
    },
    {
      labelWidth: 120,
      label: '备注',
      prop: 'modelRemark',
      type: 'textarea'
    }]
  },
  69: {
    name: '通用样本与模型质控',
    submitBtn: false,
    emptyBtn: false,
    column: [{
      label: '形成类器官天数',
      prop: 'organFormationDays',
      labelWidth: 120,
      type: 'select',
      props: {
        value: 'value',
        label: 'label'
      },
      rules: [{
        required: true,
        message: '请形成类器官天数'
      }],
      dicUrl: '/sys/dict/code/organ_formation_days'
    },
    {
      label: '增殖速率',
      prop: 'proliferationRate',
      labelWidth: 120,
      type: 'select',
      props: {
        value: 'value',
        label: 'label'
      },
      rules: [{
        required: true,
        message: '请选择增殖速率'
      }],
      dicUrl: '/sys/dict/code/proliferation_rate'
    },
    {
      label: '样本质控评分',
      prop: 'qualityControlScore',
      labelWidth: 120,
      type: 'select',
      props: {
        value: 'value',
        label: 'label'
      },
      rules: [{
        required: true,
        message: '请选择样本质控评分'
      }],
      dicUrl: '/sys/dict/code/quality_control_score'
    },
    {
      label: '培养天数',
      prop: 'cultivationDays',
      labelWidth: 120,
      type: 'select',
      props: {
        value: 'value',
        label: 'label'
      },
      rules: [{
        required: true,
        message: '请选择培养天数'
      }],
      dicUrl: '/sys/dict/code/cultivation_days'
    },
    {
      label: '类器官数量',
      prop: 'organoidNum',
      labelWidth: 120,
      type: 'select',
      props: {
        value: 'value',
        label: 'label'
      },
      rules: [{
        required: true,
        message: '请选择类器官数量'
      }],
      dicUrl: '/sys/dict/code/organoid_num'
    },
    {
      label: '消化后细胞活率',
      prop: 'pdgCellViability',
      labelWidth: 120,
      type: 'select',
      props: {
        value: 'value',
        label: 'label'
      },
      rules: [{
        required: true,
        message: '请选择消化后细胞活率'
      }],
      dicUrl: '/sys/dict/code/postdigest_cell_viability'
    }]
  },
  70: {
    name: '骨肉瘤样本与模型质控',
    submitBtn: false,
    emptyBtn: false,
    column: [{
      label: '可传代所需天数',
      prop: 'organFormationDays',
      labelWidth: 120,
      type: 'select',
      props: {
        value: 'value',
        label: 'label'
      },
      rules: [{
        required: true,
        message: '请形成类器官天数'
      }],
      dicUrl: '/sys/dict/code/organ_formation_daysgr'
    },
    {
      label: '增殖速率',
      prop: 'proliferationRate',
      labelWidth: 120,
      type: 'select',
      props: {
        value: 'value',
        label: 'label'
      },
      rules: [{
        required: true,
        message: '请选择增殖速率'
      }],
      dicUrl: '/sys/dict/code/proliferation_rate'
    },
    {
      label: '样本质控评分',
      prop: 'qualityControlScore',
      labelWidth: 120,
      type: 'select',
      props: {
        value: 'value',
        label: 'label'
      },
      rules: [{
        required: true,
        message: '请选择样本质控评分'
      }],
      dicUrl: '/sys/dict/code/quality_control_score'
    },
    {
      label: '总培养天数',
      prop: 'cultivationDays',
      labelWidth: 120,
      type: 'select',
      props: {
        value: 'value',
        label: 'label'
      },
      rules: [{
        required: true,
        message: '请选择培养天数'
      }],
      dicUrl: '/sys/dict/code/cultivation_days'
    },
    {
      label: '肿瘤细胞数量',
      prop: 'organoidNum',
      labelWidth: 120,
      type: 'select',
      props: {
        value: 'value',
        label: 'label'
      },
      rules: [{
        required: true,
        message: '请选择肿瘤细胞数量'
      }],
      dicUrl: '/sys/dict/code/organoid_numgr'
    },
    {
      label: '消化后细胞活率',
      prop: 'pdgCellViability',
      labelWidth: 120,
      type: 'select',
      props: {
        value: 'value',
        label: 'label'
      },
      rules: [{
        required: true,
        message: '请选择消化后细胞活率'
      }],
      dicUrl: '/sys/dict/code/postdigest_cell_viability'
    }]
  },
  71: {
    name: '滑膜肉瘤样本与模型质控',
    submitBtn: false,
    emptyBtn: false,
    column: [{
      label: '模型构建前培养天数',
      prop: 'organFormationDays',
      labelWidth: 120,
      type: 'select',
      props: {
        value: 'value',
        label: 'label'
      },
      rules: [{
        required: true,
        message: '请模型构建前培养天数'
      }],
      dicUrl: '/sys/dict/code/organ_formation_dayshm'
    },
    {
      label: '增殖速率',
      prop: 'proliferationRate',
      labelWidth: 120,
      type: 'select',
      props: {
        value: 'value',
        label: 'label'
      },
      rules: [{
        required: true,
        message: '请选择增殖速率'
      }],
      dicUrl: '/sys/dict/code/proliferation_rate'
    },
    {
      label: '样本质控评分',
      prop: 'qualityControlScore',
      labelWidth: 120,
      type: 'select',
      props: {
        value: 'value',
        label: 'label'
      },
      rules: [{
        required: true,
        message: '请选择样本质控评分'
      }],
      dicUrl: '/sys/dict/code/quality_control_score'
    },
    {
      label: '模型构建天数',
      prop: 'cultivationDays',
      labelWidth: 120,
      type: 'select',
      props: {
        value: 'value',
        label: 'label'
      },
      rules: [{
        required: true,
        message: '请选择模型构建天数'
      }],
      dicUrl: '/sys/dict/code/cultivation_days'
    },
    {
      label: '细胞数量',
      prop: 'organoidNum',
      labelWidth: 120,
      type: 'select',
      props: {
        value: 'value',
        label: 'label'
      },
      rules: [{
        required: true,
        message: '请选择细胞数量'
      }],
      dicUrl: '/sys/dict/code/organoid_numhm'
    },
    {
      label: '消化后细胞活率',
      prop: 'pdgCellViability',
      labelWidth: 120,
      type: 'select',
      props: {
        value: 'value',
        label: 'label'
      },
      rules: [{
        required: true,
        message: '请选择消化后细胞活率'
      }],
      dicUrl: '/sys/dict/code/postdigest_cell_viability'
    }]
  },
  35: {
    name: '样品扩增速度感受：',
    submitBtn: false,
    emptyBtn: false,
    column: [{
      labelWidth: 130,
      label: '样品扩增速度感受',
      prop: 'feeling',
      type: 'textarea'
    },
    {
      labelWidth: 120,
      label: '是否培养成功',
      prop: 'isSuccess',
      type: 'select',
      rules: [{
        required: true,
        message: '请选择是否培养成功'
      }],
      dicData: [{
        label: '否',
        value: 0
      }, {
        label: '是',
        value: 1
      }]
    },
    {
      labelWidth: 130,
      label: '是否有效样本',
      prop: 'isValid',
      type: 'select',
      dicData: [{
        label: '否',
        value: 0
      }, {
        label: '是',
        value: 1
      }],
      rules: [{
        required: true,
        message: '请选择是否为有效样本'
      }]
    }]
  },
  37: {
    name: '备注',
    submitBtn: false,
    emptyBtn: false,
    column: [{
      label: '操作人',
      prop: 'operator'
    },
    {
      label: '操作时间',
      prop: 'operateTime',
      type: 'datetime',
      format: 'yyyy-MM-dd HH:mm:ss',
      valueFormat: 'yyyy-MM-dd HH:mm:ss'
    },
    {
      label: '创建时间',
      prop: 'createTime',
      type: 'datetime',
      format: 'yyyy-MM-dd HH:mm:ss',
      valueFormat: 'yyyy-MM-dd HH:mm:ss'
    },
    {
      label: '备注',
      prop: 'remarks'
    }]
  },
  38: {
    name: '培养观察结果',
    submitBtn: false,
    emptyBtn: false,
    column: [{
      label: '生长结果评价',
      prop: 'rating',
      type: 'textarea'
    },
    {
      label: '操作时间',
      prop: 'operateTime',
      type: 'datetime',
      format: 'yyyy-MM-dd HH:mm:ss',
      valueFormat: 'yyyy-MM-dd HH:mm:ss'
    },
    {
      label: '创建时间',
      prop: 'createTime',
      type: 'datetime',
      format: 'yyyy-MM-dd HH:mm:ss',
      valueFormat: 'yyyy-MM-dd HH:mm:ss'
    },
    {
      label: '备注',
      prop: 'remarks'
    }]
  },
  39: {
    name: '冻存前照片',
    submitBtn: false,
    emptyBtn: false,
    column: [{
      label: '冻存前照片',
      prop: 'imgUrl',
      type: 'upload',
      loadText: '图片上传中，请稍等',
      span: 24,
      rules: [{
        required: false,
        message: '请上传冻存前照片'
      }],
      propsHttp: {
        home: `${fileSaveUrl}`,
        res: 'data'
      },
      tip: '只能上传jpg/png文件',
      action: '/common/fileUpload',
      listType: 'picture-card',
      labelWidth: 120
    }]
  },
  40: {
    name: '复苏',
    submitBtn: false,
    emptyBtn: false,
    column: [{
      labelWidth: 120,
      label: '复苏人',
      prop: 'recoverName',
      rules: [{
        required: true,
        message: '请输入复苏人'
      }]
    },
    {
      labelWidth: 120,
      label: '复苏日期',
      prop: 'recoverDate',
      type: 'datetime',
      format: 'yyyy-MM-dd',
      valueFormat: 'yyyy-MM-dd'
    },
    {
      labelWidth: 120,
      label: '复苏生长时间',
      prop: 'recoverTime',
      type: 'datetime',
      format: 'yyyy-MM-dd HH:mm:ss',
      valueFormat: 'yyyy-MM-dd HH:mm:ss'
    },
    {
      labelWidth: 120,
      label: '是否成功',
      prop: 'isSuccess',
      type: 'select',
      rules: [{
        required: true,
        message: '请选择是否成功'
      }],
      dicData: [{
        label: '否',
        value: 0
      }, {
        label: '是',
        value: 1
      }]
    }, {
      label: '生长情况照片',
      prop: 'imgUrl',
      type: 'upload',
      loadText: '图片上传中，请稍等',
      span: 24,
      rules: [{
        required: false,
        message: '请上传冻存前照片'
      }],
      propsHttp: {
        home: `${fileSaveUrl}`,
        res: 'data'
      },
      tip: '只能上传jpg/png文件',
      action: '/common/fileUpload',
      listType: 'picture-card',
      labelWidth: 120
    }]
  },
  77: {
    name: 'ATP测试',
    submitBtn: false,
    emptyBtn: false,
    column: [{
      labelWidth: 120,
      label: 'ATP数据上传',
      prop: 'ATPTest'
    }]
  },
  42: {
    name: '药敏结果',
    submitBtn: false,
    emptyBtn: false,
    column: [{
      labelWidth: 120,
      label: '药敏结果时间',
      prop: 'drugResultTime',
      type: 'datetime',
      format: 'yyyy-MM-dd HH:mm:ss',
      valueFormat: 'yyyy-MM-dd HH:mm:ss'
    },
    {
      labelWidth: 120,
      label: '药敏是否成功',
      prop: 'isFit',
      type: 'select',
      rules: [{
        required: true,
        message: '请选择是否成功'
      }],
      dicData: [{
        label: '否',
        value: 0
      }, {
        label: '是',
        value: 1
      }]
    },
    {
      label: '药敏结果描述',
      prop: 'growDesc',
      labelWidth: 120,
      type: 'textarea'
    }]
  },
  52: {
    name: '处理视频',
    submitBtn: false,
    emptyBtn: false,
    column: [{
      labelWidth: 120,
      label: '处理视频',
      prop: 'video',
      type: 'upload',
      loadText: '视频上传中，请稍等',
      span: 24,
      fileSize: 10240,
      rules: [{
        required: false,
        message: '请上传处理视频'
      }],
      // propsHttp: {
      //   home: `${fileSaveUrl}`,
      //   res: 'data'
      // },
      // action: '/common/fileUpload',
      // limit: 3,
      // multiple: true,
      propsHttp: {
        res: 'data',
        name: 'name',
        url: 'url'
      },
      action: '/common/oss/fileUpload',
      listType: 'picture-card',
      accept: 'video/*',
      tip: '只能上传mp4/avi/mpg/mpeg格式',
      uploadBefore: (file, done, loading, column) => {
        const suffixName = file.name.substring(file.name.lastIndexOf('.') + 1)
        const isFlag = suffixName === 'mp4' || suffixName === 'avi' || suffixName === 'mpg' || suffixName === 'mpeg'
        if (!isFlag) {
          Message({
            type: 'warning',
            message: '格式不支持! 只能上传mp4/avi/mpg/mpeg文件！'
          })
          loading() // 阻断上传
        } else {
          done() // 继续上传
        }
      },
      uploadError: (error, column) => {
        if (error === '文件太大不符合') {
          Message({
            type: 'warning',
            message: '大小超过限制！不能大于10M'
          })
        }
      }
    }]
  },
  53: {
    name: '消化酶',
    submitBtn: false,
    emptyBtn: false,
    column: [
      {
        label: '条码',
        prop: 'barcode',
        row: true
      },
      {
        label: '名称',
        prop: 'name',
        type: 'select',
        props: {
          label: 'label',
          value: 'name'
        },
        cascader: ['model'],
        dicUrl: `exp/reagent/list?objectType=53`,
        rules: [
          {
            required: true,
            message: '请选择品牌',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '型号',
        prop: 'model',
        type: 'select',
        cascader: ['specifications'],
        props: {
          label: 'label',
          value: 'model'
        },
        dicUrl: `exp/reagent/list?objectType=53&name={{key}}`,
        rules: [
          {
            required: true,
            message: '请选择型号',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '规格',
        prop: 'specifications',
        type: 'select',
        cascader: ['brand'],
        props: {
          label: 'label',
          value: 'specifications'
        },
        dicUrl: `exp/reagent/list?objectType=53&name={{name}}&model={{model}}`,
        rules: [
          {
            required: true,
            message: '请选择规格',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '品牌',
        prop: 'brand',
        type: 'select',
        cascader: ['productionDate', 'code'],
        props: {
          label: 'brand',
          value: 'brand'
        },
        dicUrl: `exp/reagent/list?objectType=53&name={{name}}&model={{model}}&specifications={{specifications}}`,
        rules: [
          {
            required: true,
            message: '请选择品牌',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '生产日期',
        prop: 'productionDate',
        type: 'select',
        cascader: ['usePeriod', 'code'],
        props: {
          label: 'productionDate',
          value: 'productionDate'
        },
        dicUrl: `exp/reagent/list?objectType=53&name={{name}}&model={{model}}&specifications={{specifications}}&brand={{brand}}`,
        rules: [
          {
            required: false,
            message: '请选择生产日期',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '使用期限',
        prop: 'usePeriod',
        type: 'select',
        props: {
          label: 'usePeriod',
          value: 'usePeriod'
        },
        dicUrl: `exp/reagent/list?objectType=53&name={{name}}&model={{model}}&specifications={{specifications}}&brand={{brand}}&productionDate={{productionDate}}`
      },
      {
        label: '编号',
        prop: 'code',
        rules: [{
          required: true,
          message: '请输入编号'
        }]
      },
      {
        label: '用量',
        prop: 'amount',
        rules: [{
          required: true,
          message: '请输入消化液用量'
        }]
      }]
  },
  54: {
    name: '载玻片',
    submitBtn: false,
    emptyBtn: false,
    column: [
      {
        label: '条码',
        prop: 'barcode',
        row: true
      },
      {
        label: '名称',
        prop: 'name',
        type: 'select',
        props: {
          label: 'label',
          value: 'name'
        },
        cascader: ['model'],
        dicUrl: `exp/consumables/list?objectType=54`,
        rules: [
          {
            required: true,
            message: '请选择品牌',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '型号',
        prop: 'model',
        type: 'select',
        cascader: ['specifications'],
        props: {
          label: 'label',
          value: 'model'
        },
        dicUrl: `exp/consumables/list?objectType=54&name={{key}}`,
        rules: [
          {
            required: true,
            message: '请选择型号',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '规格',
        prop: 'specifications',
        type: 'select',
        cascader: ['brand'],
        props: {
          label: 'label',
          value: 'specifications'
        },
        dicUrl: `exp/consumables/list?objectType=54&name={{name}}&model={{model}}`,
        rules: [
          {
            required: true,
            message: '请选择规格',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '品牌',
        prop: 'brand',
        type: 'select',
        cascader: ['productionDate', 'code'],
        props: {
          label: 'brand',
          value: 'brand'
        },
        dicUrl: `exp/consumables/list?objectType=54&name={{name}}&model={{model}}&specifications={{specifications}}`,
        rules: [
          {
            required: true,
            message: '请选择品牌',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '生产日期',
        prop: 'productionDate',
        type: 'select',
        cascader: ['usePeriod', 'code'],
        props: {
          label: 'productionDate',
          value: 'productionDate'
        },
        dicUrl: `exp/consumables/list?objectType=54&name={{name}}&model={{model}}&specifications={{specifications}}&brand={{brand}}`,
        rules: [
          {
            required: false,
            message: '请选择生产日期',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '使用期限',
        prop: 'usePeriod',
        type: 'select',
        props: {
          label: 'usePeriod',
          value: 'usePeriod'
        },
        dicUrl: `exp/consumables/list?objectType=54&name={{name}}&model={{model}}&specifications={{specifications}}&brand={{brand}}&productionDate={{productionDate}}`
      },
      {
        label: '编号',
        prop: 'code',
        rules: [{
          required: true,
          message: '请输入编号'
        }]
      },
      {
        label: '数量',
        prop: 'amount',
        rules: [{
          required: true,
          message: '请输入数量'
        }]
      }]
  },
  55: {
    name: '冻存盒',
    submitBtn: false,
    emptyBtn: false,
    column: [
      {
        label: '条码',
        prop: 'barcode',
        row: true
      },
      {
        label: '名称',
        prop: 'name',
        type: 'select',
        props: {
          label: 'label',
          value: 'name'
        },
        cascader: ['model'],
        dicUrl: `exp/consumables/list?objectType=55`,
        rules: [
          {
            required: true,
            message: '请选择品牌',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '型号',
        prop: 'model',
        type: 'select',
        cascader: ['specifications'],
        props: {
          label: 'label',
          value: 'model'
        },
        dicUrl: `exp/consumables/list?objectType=55&name={{key}}`,
        rules: [
          {
            required: true,
            message: '请选择型号',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '规格',
        prop: 'specifications',
        type: 'select',
        cascader: ['brand'],
        props: {
          label: 'label',
          value: 'specifications'
        },
        dicUrl: `exp/consumables/list?objectType=55&name={{name}}&model={{model}}`,
        rules: [
          {
            required: true,
            message: '请选择规格',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '品牌',
        prop: 'brand',
        type: 'select',
        cascader: ['productionDate', 'code'],
        props: {
          label: 'brand',
          value: 'brand'
        },
        dicUrl: `exp/consumables/list?objectType=55&name={{name}}&model={{model}}&specifications={{specifications}}`,
        rules: [
          {
            required: true,
            message: '请选择品牌',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '生产日期',
        prop: 'productionDate',
        type: 'select',
        cascader: ['usePeriod', 'code'],
        props: {
          label: 'productionDate',
          value: 'productionDate'
        },
        dicUrl: `exp/consumables/list?objectType=55&name={{name}}&model={{model}}&specifications={{specifications}}&brand={{brand}}`,
        rules: [
          {
            required: false,
            message: '请选择生产日期',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '使用期限',
        prop: 'usePeriod',
        type: 'select',
        props: {
          label: 'usePeriod',
          value: 'usePeriod'
        },
        dicUrl: `exp/consumables/list?objectType=55&name={{name}}&model={{model}}&specifications={{specifications}}&brand={{brand}}&productionDate={{productionDate}}`
      },
      {
        label: '编号',
        prop: 'code',
        rules: [{
          required: true,
          message: '请输入编号'
        }]
      },
      {
        label: '数量',
        prop: 'amount',
        rules: [{
          required: true,
          message: '请输入数量'
        }]
      }]
  },
  56: {
    name: '恒温震荡仪',
    submitBtn: false,
    emptyBtn: false,
    column: [
      {
        label: '条码',
        prop: 'barcode',
        row: true
      },
      {
        label: '名称',
        prop: 'name',
        type: 'select',
        props: {
          label: 'label',
          value: 'name'
        },
        cascader: ['model'],
        dicUrl: `exp/equipment/list?objectType=56`,
        rules: [
          {
            required: true,
            message: '请选择品牌',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '型号',
        prop: 'model',
        type: 'select',
        cascader: ['specifications'],
        props: {
          label: 'label',
          value: 'model'
        },
        dicUrl: `exp/equipment/list?objectType=56&name={{key}}`,
        rules: [
          {
            required: true,
            message: '请选择型号',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '规格',
        prop: 'specifications',
        type: 'select',
        cascader: ['brand'],
        props: {
          label: 'label',
          value: 'specifications'
        },
        dicUrl: `exp/equipment/list?objectType=56&name={{name}}&model={{model}}`,
        rules: [
          {
            required: true,
            message: '请选择规格',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '品牌',
        prop: 'brand',
        type: 'select',
        cascader: ['productionDate', 'code'],
        props: {
          label: 'brand',
          value: 'brand'
        },
        dicUrl: `exp/equipment/list?objectType=56&name={{name}}&model={{model}}&specifications={{specifications}}`,
        rules: [
          {
            required: true,
            message: '请选择品牌',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '生产日期',
        prop: 'productionDate',
        type: 'select',
        cascader: ['usePeriod', 'code'],
        props: {
          label: 'productionDate',
          value: 'productionDate'
        },
        dicUrl: `exp/equipment/list?objectType=56&name={{name}}&model={{model}}&specifications={{specifications}}&brand={{brand}}`,
        rules: [
          {
            required: false,
            message: '请选择生产日期',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '使用期限',
        prop: 'usePeriod',
        type: 'select',
        props: {
          label: 'usePeriod',
          value: 'usePeriod'
        },
        dicUrl: `exp/equipment/list?objectType=56&name={{name}}&model={{model}}&specifications={{specifications}}&brand={{brand}}&productionDate={{productionDate}}`
      },
      {
        label: '编号',
        prop: 'code',
        rules: [{
          required: true,
          message: '请输入编号'
        }]
      },
      {
        label: '震荡温度',
        prop: 'oscTemperature',
        rules: [{
          required: false,
          message: '请输入转速'
        }]
      },
      {
        label: '震荡速度',
        prop: 'oscSpeed',
        rules: [{
          required: false,
          message: '请输入时长'
        }]
      },
      {
        label: '震荡时间',
        prop: 'oscTimes',
        type: 'datetime'
      }]
  },
  57: {
    name: '生物安全柜',
    submitBtn: false,
    emptyBtn: false,
    column: [
      {
        label: '条码',
        prop: 'barcode',
        row: true
      },
      {
        label: '名称',
        prop: 'name',
        type: 'select',
        props: {
          label: 'label',
          value: 'name'
        },
        cascader: ['model'],
        dicUrl: `exp/equipment/list?objectType=57`,
        rules: [
          {
            required: true,
            message: '请选择品牌',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '型号',
        prop: 'model',
        type: 'select',
        cascader: ['specifications'],
        props: {
          label: 'label',
          value: 'model'
        },
        dicUrl: `exp/equipment/list?objectType=57&name={{key}}`,
        rules: [
          {
            required: true,
            message: '请选择型号',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '规格',
        prop: 'specifications',
        type: 'select',
        cascader: ['brand'],
        props: {
          label: 'label',
          value: 'specifications'
        },
        dicUrl: `exp/equipment/list?objectType=57&name={{name}}&model={{model}}`,
        rules: [
          {
            required: true,
            message: '请选择规格',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '品牌',
        prop: 'brand',
        type: 'select',
        cascader: ['productionDate', 'code'],
        props: {
          label: 'brand',
          value: 'brand'
        },
        dicUrl: `exp/equipment/list?objectType=57&name={{name}}&model={{model}}&specifications={{specifications}}`,
        rules: [
          {
            required: true,
            message: '请选择品牌',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '生产日期',
        prop: 'productionDate',
        type: 'select',
        cascader: ['usePeriod', 'code'],
        props: {
          label: 'productionDate',
          value: 'productionDate'
        },
        dicUrl: `exp/equipment/list?objectType=57&name={{name}}&model={{model}}&specifications={{specifications}}&brand={{brand}}`,
        rules: [
          {
            required: false,
            message: '请选择生产日期',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '使用期限',
        prop: 'usePeriod',
        type: 'select',
        props: {
          label: 'usePeriod',
          value: 'usePeriod'
        },
        dicUrl: `exp/equipment/list?objectType=57&name={{name}}&model={{model}}&specifications={{specifications}}&brand={{brand}}&productionDate={{productionDate}}`
      },
      {
        label: '编号',
        prop: 'code',
        rules: [{
          required: true,
          message: '请输入编号'
        }]
      }]
  },
  58: {
    name: '倒置显微镜',
    submitBtn: false,
    emptyBtn: false,
    column: [
      {
        label: '条码',
        prop: 'barcode',
        row: true
      },
      {
        label: '名称',
        prop: 'name',
        type: 'select',
        props: {
          label: 'label',
          value: 'name'
        },
        cascader: ['model'],
        dicUrl: `exp/equipment/list?objectType=58`,
        rules: [
          {
            required: true,
            message: '请选择品牌',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '型号',
        prop: 'model',
        type: 'select',
        cascader: ['specifications'],
        props: {
          label: 'label',
          value: 'model'
        },
        dicUrl: `exp/equipment/list?objectType=58&name={{key}}`,
        rules: [
          {
            required: true,
            message: '请选择型号',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '规格',
        prop: 'specifications',
        type: 'select',
        cascader: ['brand'],
        props: {
          label: 'label',
          value: 'specifications'
        },
        dicUrl: `exp/equipment/list?objectType=58&name={{name}}&model={{model}}`,
        rules: [
          {
            required: true,
            message: '请选择规格',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '品牌',
        prop: 'brand',
        type: 'select',
        cascader: ['productionDate', 'code'],
        props: {
          label: 'brand',
          value: 'brand'
        },
        dicUrl: `exp/equipment/list?objectType=58&name={{name}}&model={{model}}&specifications={{specifications}}`,
        rules: [
          {
            required: true,
            message: '请选择品牌',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '生产日期',
        prop: 'productionDate',
        type: 'select',
        cascader: ['usePeriod', 'code'],
        props: {
          label: 'productionDate',
          value: 'productionDate'
        },
        dicUrl: `exp/equipment/list?objectType=58&name={{name}}&model={{model}}&specifications={{specifications}}&brand={{brand}}`,
        rules: [
          {
            required: false,
            message: '请选择生产日期',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '使用期限',
        prop: 'usePeriod',
        type: 'select',
        props: {
          label: 'usePeriod',
          value: 'usePeriod'
        },
        dicUrl: `exp/equipment/list?objectType=58&name={{name}}&model={{model}}&specifications={{specifications}}&brand={{brand}}&productionDate={{productionDate}}`
      },
      {
        label: '编号',
        prop: 'code',
        rules: [{
          required: true,
          message: '请输入编号'
        }]
      }]
  },
  59: {
    name: '高内涵仪器',
    submitBtn: false,
    emptyBtn: false,
    column: [
      {
        label: '条码',
        prop: 'barcode',
        row: true
      },
      {
        label: '名称',
        prop: 'name',
        type: 'select',
        props: {
          label: 'label',
          value: 'name'
        },
        cascader: ['model'],
        dicUrl: `exp/equipment/list?objectType=59`,
        rules: [
          {
            required: true,
            message: '请选择品牌',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '型号',
        prop: 'model',
        type: 'select',
        cascader: ['specifications'],
        props: {
          label: 'label',
          value: 'model'
        },
        dicUrl: `exp/equipment/list?objectType=59&name={{key}}`,
        rules: [
          {
            required: true,
            message: '请选择型号',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '规格',
        prop: 'specifications',
        type: 'select',
        cascader: ['brand'],
        props: {
          label: 'label',
          value: 'specifications'
        },
        dicUrl: `exp/equipment/list?objectType=59&name={{name}}&model={{model}}`,
        rules: [
          {
            required: true,
            message: '请选择规格',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '品牌',
        prop: 'brand',
        type: 'select',
        cascader: ['productionDate', 'code'],
        props: {
          label: 'brand',
          value: 'brand'
        },
        dicUrl: `exp/equipment/list?objectType=59&name={{name}}&model={{model}}&specifications={{specifications}}`,
        rules: [
          {
            required: true,
            message: '请选择品牌',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '生产日期',
        prop: 'productionDate',
        type: 'select',
        cascader: ['usePeriod', 'code'],
        props: {
          label: 'productionDate',
          value: 'productionDate'
        },
        dicUrl: `exp/equipment/list?objectType=59&name={{name}}&model={{model}}&specifications={{specifications}}&brand={{brand}}`,
        rules: [
          {
            required: false,
            message: '请选择生产日期',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '使用期限',
        prop: 'usePeriod',
        type: 'select',
        props: {
          label: 'usePeriod',
          value: 'usePeriod'
        },
        dicUrl: `exp/equipment/list?objectType=59&name={{name}}&model={{model}}&specifications={{specifications}}&brand={{brand}}&productionDate={{productionDate}}`
      },
      {
        label: '编号',
        prop: 'code',
        rules: [{
          required: true,
          message: '请输入编号'
        }]
      }]
  },
  60: {
    name: '摇摆灌注仪',
    submitBtn: false,
    emptyBtn: false,
    column: [
      {
        label: '条码',
        prop: 'barcode',
        row: true
      },
      {
        label: '名称',
        prop: 'name',
        type: 'select',
        props: {
          label: 'label',
          value: 'name'
        },
        cascader: ['model'],
        dicUrl: `exp/equipment/list?objectType=60`,
        rules: [
          {
            required: true,
            message: '请选择品牌',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '型号',
        prop: 'model',
        type: 'select',
        cascader: ['specifications'],
        props: {
          label: 'label',
          value: 'model'
        },
        dicUrl: `exp/equipment/list?objectType=60&name={{key}}`,
        rules: [
          {
            required: true,
            message: '请选择型号',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '规格',
        prop: 'specifications',
        type: 'select',
        cascader: ['brand'],
        props: {
          label: 'label',
          value: 'specifications'
        },
        dicUrl: `exp/equipment/list?objectType=60&name={{name}}&model={{model}}`,
        rules: [
          {
            required: true,
            message: '请选择规格',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '品牌',
        prop: 'brand',
        type: 'select',
        cascader: ['productionDate', 'code'],
        props: {
          label: 'brand',
          value: 'brand'
        },
        dicUrl: `exp/equipment/list?objectType=60&name={{name}}&model={{model}}&specifications={{specifications}}`,
        rules: [
          {
            required: true,
            message: '请选择品牌',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '生产日期',
        prop: 'productionDate',
        type: 'select',
        cascader: ['usePeriod', 'code'],
        props: {
          label: 'productionDate',
          value: 'productionDate'
        },
        dicUrl: `exp/equipment/list?objectType=60&name={{name}}&model={{model}}&specifications={{specifications}}&brand={{brand}}`,
        rules: [
          {
            required: false,
            message: '请选择生产日期',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '使用期限',
        prop: 'usePeriod',
        type: 'select',
        props: {
          label: 'usePeriod',
          value: 'usePeriod'
        },
        dicUrl: `exp/equipment/list?objectType=60&name={{name}}&model={{model}}&specifications={{specifications}}&brand={{brand}}&productionDate={{productionDate}}`
      },
      {
        label: '编号',
        prop: 'code',
        rules: [{
          required: true,
          message: '请输入编号'
        }]
      }]
  },
  61: {
    name: '器官芯片流路控制系统',
    submitBtn: false,
    emptyBtn: false,
    column: [
      {
        label: '条码',
        prop: 'barcode',
        row: true
      },
      {
        label: '名称',
        prop: 'name',
        type: 'select',
        props: {
          label: 'label',
          value: 'name'
        },
        cascader: ['model'],
        dicUrl: `exp/equipment/list?objectType=61`,
        rules: [
          {
            required: true,
            message: '请选择品牌',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '型号',
        prop: 'model',
        type: 'select',
        cascader: ['specifications'],
        props: {
          label: 'label',
          value: 'model'
        },
        dicUrl: `exp/equipment/list?objectType=61&name={{key}}`,
        rules: [
          {
            required: true,
            message: '请选择型号',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '规格',
        prop: 'specifications',
        type: 'select',
        cascader: ['brand'],
        props: {
          label: 'label',
          value: 'specifications'
        },
        dicUrl: `exp/equipment/list?objectType=61&name={{name}}&model={{model}}`,
        rules: [
          {
            required: true,
            message: '请选择规格',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '品牌',
        prop: 'brand',
        type: 'select',
        cascader: ['productionDate', 'code'],
        props: {
          label: 'brand',
          value: 'brand'
        },
        dicUrl: `exp/equipment/list?objectType=61&name={{name}}&model={{model}}&specifications={{specifications}}`,
        rules: [
          {
            required: true,
            message: '请选择品牌',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '生产日期',
        prop: 'productionDate',
        type: 'select',
        cascader: ['usePeriod', 'code'],
        props: {
          label: 'productionDate',
          value: 'productionDate'
        },
        dicUrl: `exp/equipment/list?objectType=61&name={{name}}&model={{model}}&specifications={{specifications}}&brand={{brand}}`,
        rules: [
          {
            required: false,
            message: '请选择生产日期',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '使用期限',
        prop: 'usePeriod',
        type: 'select',
        props: {
          label: 'usePeriod',
          value: 'usePeriod'
        },
        dicUrl: `exp/equipment/list?objectType=61&name={{name}}&model={{model}}&specifications={{specifications}}&brand={{brand}}&productionDate={{productionDate}}`
      },
      {
        label: '编号',
        prop: 'code',
        rules: [{
          required: true,
          message: '请输入编号'
        }]
      }]
  },
  62: {
    name: '器官芯片环境控制系统',
    submitBtn: false,
    emptyBtn: false,
    column: [
      {
        label: '条码',
        prop: 'barcode',
        row: true
      },
      {
        label: '名称',
        prop: 'name',
        type: 'select',
        props: {
          label: 'label',
          value: 'name'
        },
        cascader: ['model'],
        dicUrl: `exp/equipment/list?objectType=62`,
        rules: [
          {
            required: true,
            message: '请选择品牌',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '型号',
        prop: 'model',
        type: 'select',
        cascader: ['specifications'],
        props: {
          label: 'label',
          value: 'model'
        },
        dicUrl: `exp/equipment/list?objectType=62&name={{key}}`,
        rules: [
          {
            required: true,
            message: '请选择型号',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '规格',
        prop: 'specifications',
        type: 'select',
        cascader: ['brand'],
        props: {
          label: 'label',
          value: 'specifications'
        },
        dicUrl: `exp/equipment/list?objectType=62&name={{name}}&model={{model}}`,
        rules: [
          {
            required: true,
            message: '请选择规格',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '品牌',
        prop: 'brand',
        type: 'select',
        cascader: ['productionDate', 'code'],
        props: {
          label: 'brand',
          value: 'brand'
        },
        dicUrl: `exp/equipment/list?objectType=62&name={{name}}&model={{model}}&specifications={{specifications}}`,
        rules: [
          {
            required: true,
            message: '请选择品牌',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '生产日期',
        prop: 'productionDate',
        type: 'select',
        cascader: ['usePeriod', 'code'],
        props: {
          label: 'productionDate',
          value: 'productionDate'
        },
        dicUrl: `exp/equipment/list?objectType=62&name={{name}}&model={{model}}&specifications={{specifications}}&brand={{brand}}`,
        rules: [
          {
            required: false,
            message: '请选择生产日期',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '使用期限',
        prop: 'usePeriod',
        type: 'select',
        props: {
          label: 'usePeriod',
          value: 'usePeriod'
        },
        dicUrl: `exp/equipment/list?objectType=62&name={{name}}&model={{model}}&specifications={{specifications}}&brand={{brand}}&productionDate={{productionDate}}`
      },
      {
        label: '编号',
        prop: 'code',
        rules: [{
          required: true,
          message: '请输入编号'
        }]
      }]
  },
  63: {
    name: '类器官自动处理工作站',
    submitBtn: false,
    emptyBtn: false,
    column: [
      {
        label: '条码',
        prop: 'barcode',
        row: true
      },
      {
        label: '名称',
        prop: 'name',
        type: 'select',
        props: {
          label: 'label',
          value: 'name'
        },
        cascader: ['model'],
        dicUrl: `exp/equipment/list?objectType=63`,
        rules: [
          {
            required: true,
            message: '请选择品牌',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '型号',
        prop: 'model',
        type: 'select',
        cascader: ['specifications'],
        props: {
          label: 'label',
          value: 'model'
        },
        dicUrl: `exp/equipment/list?objectType=63&name={{key}}`,
        rules: [
          {
            required: true,
            message: '请选择型号',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '规格',
        prop: 'specifications',
        type: 'select',
        cascader: ['brand'],
        props: {
          label: 'label',
          value: 'specifications'
        },
        dicUrl: `exp/equipment/list?objectType=63&name={{name}}&model={{model}}`,
        rules: [
          {
            required: true,
            message: '请选择规格',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '品牌',
        prop: 'brand',
        type: 'select',
        cascader: ['productionDate', 'code'],
        props: {
          label: 'brand',
          value: 'brand'
        },
        dicUrl: `exp/equipment/list?objectType=63&name={{name}}&model={{model}}&specifications={{specifications}}`,
        rules: [
          {
            required: true,
            message: '请选择品牌',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '生产日期',
        prop: 'productionDate',
        type: 'select',
        cascader: ['usePeriod', 'code'],
        props: {
          label: 'productionDate',
          value: 'productionDate'
        },
        dicUrl: `exp/equipment/list?objectType=63&name={{name}}&model={{model}}&specifications={{specifications}}&brand={{brand}}`,
        rules: [
          {
            required: false,
            message: '请选择生产日期',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '使用期限',
        prop: 'usePeriod',
        type: 'select',
        props: {
          label: 'usePeriod',
          value: 'usePeriod'
        },
        dicUrl: `exp/equipment/list?objectType=63&name={{name}}&model={{model}}&specifications={{specifications}}&brand={{brand}}&productionDate={{productionDate}}`
      },
      {
        label: '编号',
        prop: 'code',
        rules: [{
          required: true,
          message: '请输入编号'
        }]
      }]
  },
  64: {
    name: '培养实验准备组件',
    submitBtn: false,
    emptyBtn: false,
    column: [
      {
        labelWidth: 180,
        label: '基质胶已化冻',
        prop: 'isFrozen',
        type: 'select',
        props: {
          value: 'value',
          label: 'label'
        },
        dicUrl: '/sys/dict/code/yes_no'
      },
      {
        labelWidth: 180,
        label: '冰盒准备就绪',
        prop: 'isBoxReady',
        type: 'select',
        props: {
          value: 'value',
          label: 'label'
        },
        dicUrl: '/sys/dict/code/yes_no'
      },
      {
        label: '紫外灯照射时间≥30min',
        prop: 'isUV30',
        labelWidth: 180,
        type: 'select',
        props: {
          value: 'value',
          label: 'label'
        },
        dicUrl: '/sys/dict/code/yes_no'
      },
      {
        label: '恒温震荡仪准备就绪',
        prop: 'isShakerReady',
        labelWidth: 180,
        type: 'select',
        props: {
          value: 'value',
          label: 'label'
        },
        dicUrl: '/sys/dict/code/yes_no'
      }]
  },
  65: {
    name: '冻存准备组件',
    submitBtn: false,
    emptyBtn: false,
    column: [
      {
        labelWidth: 180,
        label: '细胞是否污染',
        prop: 'isPolluted',
        type: 'select',
        props: {
          value: 'value',
          label: 'label'
        },
        dicUrl: '/sys/dict/code/yes_no'
      },
      {
        labelWidth: 180,
        label: '符合冻存标准',
        prop: 'isFrozenStandard',
        type: 'select',
        props: {
          value: 'value',
          label: 'label'
        },
        dicUrl: '/sys/dict/code/yes_no'
      },
      {
        label: 'PBS预冷时间≥30min',
        prop: 'isPBS30',
        labelWidth: 180,
        type: 'select',
        props: {
          value: 'value',
          label: 'label'
        },
        dicUrl: '/sys/dict/code/yes_no'
      },
      {
        label: 'TrypLE已恢复至室温',
        prop: 'isTrypLERoom',
        labelWidth: 180,
        type: 'select',
        props: {
          value: 'value',
          label: 'label'
        },
        dicUrl: '/sys/dict/code/yes_no'
      },
      {
        label: '冻存盒已恢复至室温',
        prop: 'isFrozenBoxRoom',
        labelWidth: 180,
        type: 'select',
        props: {
          value: 'value',
          label: 'label'
        },
        dicUrl: '/sys/dict/code/yes_no'
      },
      {
        label: '紫外灯照射时间≥30min',
        prop: 'isUV30M',
        labelWidth: 180,
        type: 'select',
        props: {
          value: 'value',
          label: 'label'
        },
        dicUrl: '/sys/dict/code/yes_no'
      }]
  },
  66: {
    name: '复苏准备组件',
    submitBtn: false,
    emptyBtn: false,
    column: [
      {
        labelWidth: 180,
        label: '基质胶已化冻',
        prop: 'isMatrixFrozen',
        type: 'select',
        props: {
          value: 'value',
          label: 'label'
        },
        dicUrl: '/sys/dict/code/yes_no'
      },
      {
        labelWidth: 180,
        label: '冰盒准备就绪',
        prop: 'isIceBoxReady',
        type: 'select',
        props: {
          value: 'value',
          label: 'label'
        },
        dicUrl: '/sys/dict/code/yes_no'
      },
      {
        label: '紫外灯照射时间≥30min',
        prop: 'isUVOver',
        labelWidth: 180,
        type: 'select',
        props: {
          value: 'value',
          label: 'label'
        },
        dicUrl: '/sys/dict/code/yes_no'
      },
      {
        label: '水浴锅准备就绪',
        prop: 'isWaterBathReady',
        labelWidth: 180,
        type: 'select',
        props: {
          value: 'value',
          label: 'label'
        },
        dicUrl: '/sys/dict/code/yes_no'
      }]
  },
  67: {
    name: '细胞培养瓶',
    submitBtn: false,
    emptyBtn: false,
    column: [
      {
        label: '条码',
        prop: 'barcode',
        row: true
      },
      {
        label: '名称',
        prop: 'name',
        type: 'select',
        props: {
          label: 'label',
          value: 'name'
        },
        cascader: ['model'],
        dicUrl: `exp/consumables/list?objectType=67`,
        rules: [
          {
            required: true,
            message: '请选择品牌',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '型号',
        prop: 'model',
        type: 'select',
        cascader: ['specifications'],
        props: {
          label: 'label',
          value: 'model'
        },
        dicUrl: `exp/consumables/list?objectType=67&name={{key}}`,
        rules: [
          {
            required: true,
            message: '请选择型号',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '规格',
        prop: 'specifications',
        type: 'select',
        cascader: ['brand'],
        props: {
          label: 'label',
          value: 'specifications'
        },
        dicUrl: `exp/consumables/list?objectType=67&name={{name}}&model={{model}}`,
        rules: [
          {
            required: true,
            message: '请选择规格',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '品牌',
        prop: 'brand',
        type: 'select',
        cascader: ['productionDate', 'code'],
        props: {
          label: 'brand',
          value: 'brand'
        },
        dicUrl: `exp/consumables/list?objectType=67&name={{name}}&model={{model}}&specifications={{specifications}}`,
        rules: [
          {
            required: true,
            message: '请选择品牌',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '生产日期',
        prop: 'productionDate',
        type: 'select',
        cascader: ['usePeriod', 'code'],
        props: {
          label: 'productionDate',
          value: 'productionDate'
        },
        dicUrl: `exp/consumables/list?objectType=67&name={{name}}&model={{model}}&specifications={{specifications}}&brand={{brand}}`,
        rules: [
          {
            required: false,
            message: '请选择生产日期',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '使用期限',
        prop: 'usePeriod',
        type: 'select',
        props: {
          label: 'usePeriod',
          value: 'usePeriod'
        },
        dicUrl: `exp/consumables/list?objectType=67&name={{name}}&model={{model}}&specifications={{specifications}}&brand={{brand}}&productionDate={{productionDate}}`
      },
      {
        label: '编号',
        prop: 'code',
        rules: [{
          required: true,
          message: '请输入编号'
        }]
      },
      {
        label: '数量',
        prop: 'amount',
        rules: [{
          required: true,
          message: '请输入数量'
        }]
      }]
  },
  68: {
    name: '添加剂',
    submitBtn: false,
    emptyBtn: false,
    column: [
      {
        label: '条码',
        prop: 'barcode',
        row: true
      },
      {
        label: '名称',
        prop: 'name',
        type: 'select',
        props: {
          label: 'label',
          value: 'name'
        },
        cascader: ['model'],
        dicUrl: `exp/reagent/list?objectType=68`,
        rules: [
          {
            required: true,
            message: '请选择品牌',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '型号',
        prop: 'model',
        type: 'select',
        cascader: ['specifications'],
        props: {
          label: 'label',
          value: 'model'
        },
        dicUrl: `exp/reagent/list?objectType=68&name={{key}}`,
        rules: [
          {
            required: true,
            message: '请选择型号',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '规格',
        prop: 'specifications',
        type: 'select',
        cascader: ['brand'],
        props: {
          label: 'label',
          value: 'specifications'
        },
        dicUrl: `exp/reagent/list?objectType=68&name={{name}}&model={{model}}`,
        rules: [
          {
            required: true,
            message: '请选择规格',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '品牌',
        prop: 'brand',
        type: 'select',
        cascader: ['productionDate', 'code'],
        props: {
          label: 'brand',
          value: 'brand'
        },
        dicUrl: `exp/reagent/list?objectType=68&name={{name}}&model={{model}}&specifications={{specifications}}`,
        rules: [
          {
            required: true,
            message: '请选择品牌',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '生产日期',
        prop: 'productionDate',
        type: 'select',
        cascader: ['usePeriod', 'code'],
        props: {
          label: 'productionDate',
          value: 'productionDate'
        },
        dicUrl: `exp/reagent/list?objectType=68&name={{name}}&model={{model}}&specifications={{specifications}}&brand={{brand}}`,
        rules: [
          {
            required: false,
            message: '请选择生产日期',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '使用期限',
        prop: 'usePeriod',
        type: 'select',
        props: {
          label: 'usePeriod',
          value: 'usePeriod'
        },
        dicUrl: `exp/reagent/list?objectType=68&name={{name}}&model={{model}}&specifications={{specifications}}&brand={{brand}}&productionDate={{productionDate}}`
      },
      {
        label: '编号',
        prop: 'code',
        rules: [{
          required: true,
          message: '请输入编号'
        }]
      },
      {
        label: '用量',
        prop: 'amount',
        rules: [{
          required: true,
          message: '请输入消化液用量'
        }]
      }]
  },
  76: {
    name: '混匀仪',
    submitBtn: false,
    emptyBtn: false,
    column: [
      {
        label: '条码',
        prop: 'barcode',
        row: true
      },
      {
        label: '名称',
        prop: 'name',
        type: 'select',
        props: {
          label: 'label',
          value: 'name'
        },
        cascader: ['model'],
        dicUrl: `exp/equipment/list?objectType=76`,
        rules: [
          {
            required: true,
            message: '请选择品牌',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '型号',
        prop: 'model',
        type: 'select',
        cascader: ['specifications'],
        props: {
          label: 'label',
          value: 'model'
        },
        dicUrl: `exp/equipment/list?objectType=76&name={{key}}`,
        rules: [
          {
            required: true,
            message: '请选择型号',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '规格',
        prop: 'specifications',
        type: 'select',
        cascader: ['brand'],
        props: {
          label: 'label',
          value: 'specifications'
        },
        dicUrl: `exp/equipment/list?objectType=76&name={{name}}&model={{model}}`,
        rules: [
          {
            required: true,
            message: '请选择规格',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '品牌',
        prop: 'brand',
        type: 'select',
        cascader: ['productionDate', 'code'],
        props: {
          label: 'brand',
          value: 'brand'
        },
        dicUrl: `exp/equipment/list?objectType=76&name={{name}}&model={{model}}&specifications={{specifications}}`,
        rules: [
          {
            required: true,
            message: '请选择品牌',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '生产日期',
        prop: 'productionDate',
        type: 'select',
        cascader: ['usePeriod', 'code'],
        props: {
          label: 'productionDate',
          value: 'productionDate'
        },
        dicUrl: `exp/equipment/list?objectType=76&name={{name}}&model={{model}}&specifications={{specifications}}&brand={{brand}}`,
        rules: [
          {
            required: false,
            message: '请选择生产日期',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '使用期限',
        prop: 'usePeriod',
        type: 'select',
        props: {
          label: 'usePeriod',
          value: 'usePeriod'
        },
        dicUrl: `exp/equipment/list?objectType=76&name={{name}}&model={{model}}&specifications={{specifications}}&brand={{brand}}&productionDate={{productionDate}}`
      },
      {
        label: '编号',
        prop: 'code',
        rules: [{
          required: true,
          message: '请输入混匀仪编号'
        }],
        labelWidth: 100
      },
      {
        label: '摇床振幅（rpm）',
        prop: 'shakerAmplitude',
        labelWidth: 140
      },
      {
        label: '振动时间（min）',
        prop: 'vibrateTime',
        labelWidth: 140
      }]
  },
  78: {
    name: '酶标仪',
    submitBtn: false,
    emptyBtn: false,
    column: [
      {
        label: '条码',
        prop: 'barcode',
        row: true
      },
      {
        label: '名称',
        prop: 'name',
        type: 'select',
        props: {
          label: 'label',
          value: 'name'
        },
        cascader: ['model'],
        dicUrl: `exp/equipment/list?objectType=78`,
        rules: [
          {
            required: true,
            message: '请选择品牌',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '型号',
        prop: 'model',
        type: 'select',
        cascader: ['specifications'],
        props: {
          label: 'label',
          value: 'model'
        },
        dicUrl: `exp/equipment/list?objectType=78&name={{key}}`,
        rules: [
          {
            required: true,
            message: '请选择型号',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '规格',
        prop: 'specifications',
        type: 'select',
        cascader: ['brand'],
        props: {
          label: 'label',
          value: 'specifications'
        },
        dicUrl: `exp/equipment/list?objectType=78&name={{name}}&model={{model}}`,
        rules: [
          {
            required: true,
            message: '请选择规格',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '品牌',
        prop: 'brand',
        type: 'select',
        cascader: ['productionDate', 'code'],
        props: {
          label: 'brand',
          value: 'brand'
        },
        dicUrl: `exp/equipment/list?objectType=78&name={{name}}&model={{model}}&specifications={{specifications}}`,
        rules: [
          {
            required: true,
            message: '请选择品牌',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '生产日期',
        prop: 'productionDate',
        type: 'select',
        cascader: ['usePeriod', 'code'],
        props: {
          label: 'productionDate',
          value: 'productionDate'
        },
        dicUrl: `exp/equipment/list?objectType=78&name={{name}}&model={{model}}&specifications={{specifications}}&brand={{brand}}`,
        rules: [
          {
            required: false,
            message: '请选择生产日期',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '使用期限',
        prop: 'usePeriod',
        type: 'select',
        props: {
          label: 'usePeriod',
          value: 'usePeriod'
        },
        dicUrl: `exp/equipment/list?objectType=78&name={{name}}&model={{model}}&specifications={{specifications}}&brand={{brand}}&productionDate={{productionDate}}`
      },
      {
        label: '编号',
        prop: 'code',
        rules: [{
          required: true,
          message: '请输入编号'
        }]
      }]
  }
}
export default option
