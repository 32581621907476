import { request } from '@/util/http'

export const remove = (ids) => {
  return request({
    url: '/exp/subCulture/remove',
    method: 'post',
    params: {
      ids
    }
  })
}

export const submit = (row) => {
  return request({
    url: '/exp/subCulture/submit',
    method: 'post',
    data: row
  })
}
// 保存传代培养
export const submitVr = (data) => {
  return request({
    url: '/vr/exp/subCulture/submit',
    method: 'post',
    data
  })
}
// 获取步骤详情
export const getStepDetail = (stepId) => {
  return request({
    url: '/vr/exp/subCulture/step/detail',
    method: 'get',
    params: {
      stepId
    }
  })
}
