<template>
<div :style="'color:' + activeColor">
  {{ timerValue | getTime}}
</div>
</template>

<script>

import { getMsgColor } from '@/api/setting/setting'
export default {
  name: 'GoodTimer',
  data () {
    return {
      isStop: false,
      timerValue: 0,
      msgColor: [{
        value: '#000'
      }, {
        value: '#000'
      }, {
        value: '#000'
      }],
      msgColorIndex: 0,
      activeColor: '#ccc'
    }
  },
  props: {
    value: {
      type: Number,
      default: 0
    }
  },
  watch: {
    value (val) {
      this.timerValue = val
    }
  },
  filters: {
    getTime (value) {
      let secondTime = parseInt(value) // 秒
      if (value <= 0) {
        return '已超时'
      }
      let minuteTime = 0 // 分
      let hourTime = 0 // 时
      if (secondTime > 60) {
        minuteTime = parseInt(secondTime / 60)
        secondTime = parseInt(secondTime % 60)
        if (minuteTime > 60) {
          hourTime = parseInt(minuteTime / 60)
          minuteTime = parseInt(minuteTime % 60)
        }
      }
      secondTime = secondTime < 10 ? '0' + secondTime : secondTime
      let result = '' + secondTime + ''
      if (minuteTime > 0) {
        minuteTime = minuteTime < 10 ? '0' + minuteTime : minuteTime
        result = '' + minuteTime + ':' + result
      } else {
        result = '' + '00' + ':' + result
      }
      if (hourTime > 0) {
        hourTime = hourTime < 10 ? '0' + hourTime : hourTime
        result = '' + hourTime + ':' + result
      } else {
        result = '' + '00' + ':' + result
      }
      return result
    }
  },
  mounted () {
    this.timerValue = this.value
    getMsgColor().then(res => {
      if (res.data.length) {
        this.msgColor = res.data
      }
    })
  },
  methods: {
    stop () {
      this.isStop = true
    },
    start () {
      this.isStop = false
      this.startTimer()
    },
    startTimer () {
      setTimeout(() => {
        if (!this.isStop && this.timerValue > 0) {
          this.startTimer()
          this.timerValue -= 1
          this.change(this.timerValue)
        }
      }, 1000)
    },
    change (val) {
      const _value = val
      let index = 0
      if (_value < 300) {
        index = 2
      } else if (_value < 1800) {
        index = 1
      } else if (_value < 3600) {
        index = 0
      }
      this.activeColor = this.msgColor[index].value
      if (_value < 0) {
        this.activeColor = '#ccc'
      }
    }
  }
}
</script>

<style scoped>

</style>
