import { request } from '@/util/http'

export const getList = (current, size, params) => {
  return request({
    url: '/exp/experiment/list',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const getDetail = (id) => {
  return request({
    url: '/exp/experiment/detail',
    method: 'get',
    params: {
      id
    }
  })
}
export const getScan = (code, objectType) => {
  return request({
    url: '/navigate/scan/' + code + '/' + objectType,
    method: 'get'
  })
}
export const remove = (ids) => {
  return request({
    url: '/exp/experiment/remove',
    method: 'post',
    params: {
      ids
    }
  })
}

export const submit = (row) => {
  return request({
    url: '/exp/experiment/submit',
    method: 'post',
    data: row
  })
}

export const list = (params) => {
  return request({
    url: '/exp/experiment/list',
    method: 'get',
    params: {
      ...params
    }
  })
}

export const tubeList = (params) => {
  return request({
    url: '/exp/experiment/vr/listTube',
    method: 'get',
    params: {
      ...params
    }
  })
}

export const generationDic = (params) => {
  return request({
    url: '/exp/experiment/generationDic',
    method: 'get',
    params: {
      ...params
    }
  })
}
export const expDeviceresult = (holeId, day, firstImgFlag) => {
  return request({
    url: `/exp/deviceresult/result/${holeId}/${day}`,
    method: 'get',
    params: {
      firstImgFlag
    }
  })
}
export const downloadDeviceresult = (path) => {
  return request({
    url: `/common/download/${path}`,
    method: 'get',
    responseType: 'blob'
  })
}
export const delDeviceresult = (id, flag) => {
  return request({
    url: `/exp/deviceresult/delete/${id}/${flag}`,
    method: 'post'
  })
}
export const uploadTubeFile = (data) => {
  return request({
    url: '/exp/deviceresult/submit',
    method: 'post',
    data
  })
}
export const expObjectCodeDic = (params) => {
  return request({
    url: '/exp/experiment/expObjectCodeDic',
    method: 'get',
    params: {
      ...params
    }
  })
}

export const getRemind = (params) => {
  return request({
    url: '/exp/experiment/remind',
    method: 'get',
    params: {
      ...params
    }
  })
}

export const downloadImg = (url) => {
  return request({
    url: url,
    method: 'get',
    responseType: 'arraybuffer'
  })
}
// 根据实验ID获取孔板数据
export const getPlates = (params) => {
  return request({
    url: '/vr/exp/experimentplate/platesByExpId',
    method: 'get',
    params
  })
}

// 实验第一步获取原代或传代的铺板信息-改版
export const getNewTube = (params) => {
  return request({
    url: '/exp/experiment/vr/listTube',
    method: 'get',
    params
  })
}
// 培养照片的观察天
export const checkDay = (params) => {
  return request({
    url: '/exp/experiment/day/observe',
    method: 'get',
    params
  })
}

// 培养照片的观察天
export const checkHole = (params) => {
  return request({
    url: '/exp/experiment/hole/observe',
    method: 'get',
    params
  })
}
export const userImgFlag = (params) => {
  return request({
    url: '/exp/deviceresult/check/image',
    method: 'get',
    params
  })
}

